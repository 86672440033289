import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Image from 'images/sucesso.svg';
import { Title, Wrapper } from './styles';
import { FormContainer } from 'styles/global';
import { Link as RouterLink } from 'react-router-dom';

import BackButton from 'components/BackButton';
import { useAuth } from 'contexts/auth';

function Sucesso() {
  const { user } = useAuth();

  return (
    <FormContainer maxWidth={400}>
      <RouterLink style={{ textDecoration: 'none' }} to="/login">
        <BackButton>Alterar CPF</BackButton>
      </RouterLink>
      <Wrapper>
        <img src={Image} alt="Não tem pendências" />
        <Typography variant="h6" style={{ color: 'white', fontSize: 16 }}>
          Não localizamos nenhuma pendência cadastrada na Bellinati, por favor
          entre em contato conosco para maiores detalhes.
        </Typography>
      </Wrapper>
    </FormContainer>
  );
}

export default Sucesso;
