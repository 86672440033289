import React, { useEffect, useState, useMemo } from 'react';

import api from 'services/api';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'contexts/auth';
import { toast } from 'react-toastify';
import { decryptData } from 'utils/crypto';
import ItauCredDividaDetalhe from './ItauCredDividaDetalhe';
import GenericDividaDetalhe from './GenericDividaDetalhe';
import { IDivida } from 'interfaces/Divida';
import {
  IBuscaOpcaoPagamento,
  IOpcaoPagamento,
} from 'interfaces/BuscaOpcaoPagamento';
import BradescoDividaDetalhe from './BradescoDividaDetalhe';
import { IBoleto } from 'interfaces/Boleto';
import { ICustomizacao } from 'interfaces/Customizacao';
import { buscaCustomService } from 'services/buscaCustomizacao/buscaCustomizacao';
import { logEvent } from 'config/googleAnalytics';
import BradescoReorgDividaDetalhe from './BradescoReorgDividaDetalhe';
import { forceFileDownload } from "services/download";

const copyToClipboard = (str: any) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const DividaDetalhe: React.FC = () => {
  const [custom, setCustom] = useState<ICustomizacao>({});
  const history = useHistory();
  const [divida, setDivida] = useState<IDivida>(
    decryptData(localStorage.getItem('@dividaSucesso') || '{}'),
  );
  const [boleto, setBoleto] = useState<IBoleto>(
    decryptData(localStorage.getItem('@retornoEmitirBoleto') || '{}'),
  );
  const opcao: IBuscaOpcaoPagamento = decryptData(
    localStorage.getItem('@opcao') || '{}',
  );
  const [parcelamento, setParcelamento] = useState<IOpcaoPagamento>({});
  const [resumoBoleto, setResumoBoleto] = useState({});

  const [whatsapp, setWhatsapp] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [showWhatsApp, setShowWhatsApp] = useState(false);
  const { user, bank } = useAuth();
  const [loadingBaixarBoleto, setLoadingBaixarBoleto] = useState(false);
  const [loadingEnviarBoleto, setLoadingEnviarBoleto] = useState(false);

  const usaResumoApi: boolean = useMemo(() => {
    return !!opcao?.chamarResumoBoleto;
  }, [opcao]);

  const mostraCamposBoletoEmailTelefone: boolean = useMemo(() => {
    return !!boleto?.acordoComBoleto;
  }, [boleto]);

  const crms = useMemo(
    () => [...new Set(user?.credores?.map(item => item?.crms).flat() || [])],
    [user],
  );

  useEffect(() => {
    async function buscaCustomizacao() {
      const { data: newCustom } = await buscaCustomService({
        crms: String(divida?.crm),
        carteiraId: divida?.carteiraId,
      })
      setCustom(newCustom);
    }
    buscaCustomizacao();
  },[]);
  
  useEffect(() => {
    async function verificaPermissaoEnvioWhatsapp() {
      const { data } = await api.post('verifica-permissao-envio-whatsapp', {
        crmIdParaBpMessage: divida?.crm,
        carteiraId: divida?.carteiraId,
        fase: '',
      });
      setShowWhatsApp(data.permissao);
      // setShowWhatsApp(true);
    }

    verificaPermissaoEnvioWhatsapp();
  }, [divida]);

  useEffect(() => {
    function getDividaSucesso() {
      const localDivida = localStorage.getItem('@dividaSucesso');
      const localBoleto = localStorage.getItem('@retornoEmitirBoleto');
      const localParcelamento = localStorage.getItem('@parcelamentoAcordo');
      const localResumoBoleto = localStorage.getItem('@resumoBoleto');
      if (!localDivida || !localBoleto) {
        history.push('/');
        return;
      }
      setDivida(decryptData(localDivida));
      setBoleto(decryptData(localBoleto));
      setParcelamento(decryptData(localParcelamento || '{}'));
      setResumoBoleto(decryptData(localResumoBoleto || '{}'));
    }

    getDividaSucesso();
  }, [history]);

  const formattedDate = useMemo(() => {
    if (!boleto.vcto) return;
    const [onlyDate] = boleto?.vcto?.toString().split('T');
    const [year, month, date] = onlyDate.split('-');
    return `${date}/${month}/${year}`;
  }, [boleto]);

  /**
   * Tipos:
     E-mail = 1
     Sms = 2
     WhatsApp = 3

    Método responsável por enviar o boleto por e-mail, SMS ou WhatsApp.
    Obs: para enviar o boleto por WhatsApp é necessário verificar se a carteira possui permissão(rota default) para enviar WhatsApp.
  */
  async function enviarBoletos(tipo: number) {
    setLoadingEnviarBoleto(true);
    const { data } = await api.post('enviar-boleto', {
      GuidBoleto: boleto.guidBoleto,
      cnpJ_CPF: user?.cnpJ_CPF,
      dataVencimento: parcelamento?.dataVencimento,
      contrato: !!divida?.contratos?.length && divida?.contratos[0].numero,
      crm: divida?.crm,
      carteira: divida?.carteiraId,
      tipo: tipo,
      email: email,
      telefone:
        tipo === 3
          ? !!whatsapp && whatsapp.substring(0, 15)
          : !!telefone && telefone.substring(0, 15),
      CrmIdParaBpMessage: 0,
    });

    if (!data.sucesso) {
      toast.error(data.msgRetorno || 'Erro no envio do boleto!');
      setLoadingEnviarBoleto(false);
      return;
    }

    toast.success(data.msgRetorno || 'Boleto enviado com sucesso!');
    setLoadingEnviarBoleto(false);
    
    let sendingChannel = tipo === 1 ? 'E-mail' : tipo === 2 ? 'Telefone' : 'Whatsapp';

    logEvent(`Enviou boleto por ${sendingChannel}`, divida?.carteiraId, divida?.crm, user?.cnpJ_CPF, 
      divida?.carteiraCrmText);
  }

  const onClickBaixarBoleto = async () => {
    try {
      setLoadingBaixarBoleto(true);

      if (boleto)
        setBoleto(
          decryptData(localStorage.getItem('@retornoEmitirBoleto') || '{}'),
        );

      const response = await api.post(
        'download-boleto',
        {
          GuidBoleto: boleto.guidBoleto,
          UrlBoleto: boleto.urlBoleto,
          cnpj_cpf: user?.cnpJ_CPF,
          contrato: !!divida?.contratos?.length && divida?.contratos[0]?.numero,
          crm: divida?.crm,
          fileName: `Boleto_${!!divida?.contratos?.length &&
            divida.contratos[0]?.numero}_${new Date().getTime()}.pdf`,
          carteiraId: divida?.carteiraId
        },
        {
          responseType: 'blob',
        },
      );
      
      logEvent('Baixou boleto', divida?.carteiraId, divida?.crm,
       user?.cnpJ_CPF, divida?.carteiraCrmText);

      const blob = new Blob([response.data], {
        type: 'application/pdf',
      });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    } finally {
      setLoadingBaixarBoleto(false);
    }
  };

  const downloadAgenda = async () => {
    try{
      setLoadingBaixarBoleto(true);

      const response = await api.post(
        'download-agenda',
        {
          CnpjCpf: user?.cnpJ_CPF,
        },
      );

      forceFileDownload(response, `${user?.nome}_${new Date().getTime()}.ics`);
    } finally {
      setLoadingBaixarBoleto(false);
    }
  };

  if (usaResumoApi && bank?.financeira?.toLowerCase() === 'itau') {
    return (
      <ItauCredDividaDetalhe
        carteiraId={Number(divida?.carteiraId)}
        crmId={divida?.crm}
        custom={custom}
        history={history}
        boleto={boleto}
        resumoBoleto={resumoBoleto}
        parcelamento={parcelamento}
        whatsapp={whatsapp}
        setWhatsapp={setWhatsapp}
        email={email}
        setEmail={setEmail}
        telefone={telefone}
        setTelefone={setTelefone}
        showWhatsApp={showWhatsApp}
        loadingBaixarBoleto={loadingBaixarBoleto}
        loadingEnviarBoleto={loadingEnviarBoleto}
        onClickBaixarBoleto={onClickBaixarBoleto}
        enviarBoletos={enviarBoletos}
        formattedDate={formattedDate}
        copyToClipboard={copyToClipboard}
        mostraCamposBoletoEmailTelefone={mostraCamposBoletoEmailTelefone}
        downloadAgenda={downloadAgenda}
      />
    );
  } else if (bank?.financeira?.toLowerCase() === 'bradesco') {
    return (
      divida?.tipoContrato === 'REORGPF' ? (
        <BradescoReorgDividaDetalhe
          carteiraId={Number(divida?.carteiraId)}
          crmId={divida?.crm}
          custom={custom}
          history={history}
          boleto={boleto}
          parcelamento={parcelamento}
          whatsapp={whatsapp}
          setWhatsapp={setWhatsapp}
          email={email}
          setEmail={setEmail}
          telefone={telefone}
          setTelefone={setTelefone}
          showWhatsApp={showWhatsApp}
          loadingBaixarBoleto={loadingBaixarBoleto}
          loadingEnviarBoleto={loadingEnviarBoleto}
          onClickBaixarBoleto={onClickBaixarBoleto}
          enviarBoletos={enviarBoletos}
          copyToClipboard={copyToClipboard}
          mostraCamposBoletoEmailTelefone={mostraCamposBoletoEmailTelefone}
        />
      ) : (
        <BradescoDividaDetalhe
          carteiraId={Number(divida?.carteiraId)}
          crmId={divida?.crm}
          custom={custom}
          history={history}
          boleto={boleto}
          parcelamento={parcelamento}
          whatsapp={whatsapp}
          setWhatsapp={setWhatsapp}
          email={email}
          setEmail={setEmail}
          telefone={telefone}
          setTelefone={setTelefone}
          showWhatsApp={showWhatsApp}
          loadingBaixarBoleto={loadingBaixarBoleto}
          loadingEnviarBoleto={loadingEnviarBoleto}
          onClickBaixarBoleto={onClickBaixarBoleto}
          enviarBoletos={enviarBoletos}
          copyToClipboard={copyToClipboard}
          mostraCamposBoletoEmailTelefone={mostraCamposBoletoEmailTelefone}
          downloadAgenda={downloadAgenda}
        />
      ) 
    );
  } else {
    return (
      <GenericDividaDetalhe
        carteiraId={Number(divida?.carteiraId)}
        crmId={divida?.crm}
        custom={custom}
        history={history}
        boleto={boleto}
        parcelamento={parcelamento}
        whatsapp={whatsapp}
        setWhatsapp={setWhatsapp}
        email={email}
        setEmail={setEmail}
        telefone={telefone}
        setTelefone={setTelefone}
        showWhatsApp={showWhatsApp}
        loadingBaixarBoleto={loadingBaixarBoleto}
        loadingEnviarBoleto={loadingEnviarBoleto}
        onClickBaixarBoleto={onClickBaixarBoleto}
        enviarBoletos={enviarBoletos}
        copyToClipboard={copyToClipboard}
        mostraCamposBoletoEmailTelefone={mostraCamposBoletoEmailTelefone}
        downloadAgenda={downloadAgenda}
      />
    );
  }
};

export default DividaDetalhe;
