import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import LogoHorizontal from 'images/logo-white-horizontal.svg';
import BradescoBpLogo from 'images/bradesco-bp-logo.png';
import Logo from 'images/logo-white.svg';
import Logout from 'images/logout-white.svg';
import {
  Box,
  Button,
  Container,
  Hidden,
  IconButton,
  Link,
} from '@material-ui/core';
import Drawer from 'components/Drawer';
import { If, Then, Else } from 'react-if';
import HamburgerIcon from 'images/hamburger.svg';
import BradescoHambugerIcon from 'images/breadesco-hamburger.svg';
import { useAuth } from 'contexts/auth';

const Wrapper = styled.header.attrs(props => ({
  isBradesco: props.isBradesco,
  logged: props.logged,
}))`
  flex-shrink: 0;
  background-color: ${props =>
    !props.isBradesco ? props.theme.palette.darkOrange : '#fff'};
  padding: ${props => (!props.isBradesco ? `20px 0` : `22.5px 0`)};
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.isBradesco && (
    'box-shadow: 0px 3px 10px 0px rgba(77, 78, 83, 0.20); ' +
    'font-family: "Bradesco-Sans-Regular", sans-serif;'
  )}
  ${props => (props.isBradesco && !props.logged) && (
    'position: fixed; top: 0; ' +
    'z-index: 100; width: 100%;'
  )}

  ${props =>
    props.theme.breakpoints.down('sm') && !props.isBradesco
      ? `10px 0`
      : props.theme.breakpoints.down('sm') && `11.5px 0`}
`;

const HeaderLinkList = styled.div`
  font-family: Raleway;
  font-weight: 800;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const HeaderLink = styled(Link)`
  font-size: 14px;
  text-decoration: none;
  font-family: 'Raleway';

  ${props =>
    !props.color &&
    css`
      color: #fff;
    `}

  & + & {
    margin-left: 50px;
  }
`;

const BradescoHeaderLink = styled(Link)`
  font-size: 14px;
  text-decoration: none;

  color: #4D4E53;

  & + & {
    margin-left: 50px;
  }
`;

const Separator = styled.div`
  width: 2px;
  height: 30px;
  background-color: ${props => props.theme.palette.primary.separator};
  margin: 0 50px;
`;

const HeaderImage = styled.img.attrs(props => ({
  isBradesco: props.isBradesco,
}))`
  margin-right: 120px;
  padding-bottom: 5px;
  cursor: pointer;
  max-width: ${props => (props.isBradesco ? `25%` : `15%`)};

  ${props => props.theme.breakpoints.down('sm')} {
    margin-right: auto;
    max-width: 25%;
  }
`;

const LogoutButton = styled(Button).attrs({
  startIcon: <img src={Logout} alt="Logout Icon" />,
})`
  color: #fff;
  text-transform: none;
`;

const menuItems = [
  {
    label: 'Contato',
    href: 'https://www.bellinatiperez.com.br/fale-com-a-gente/',
  },
  {
    separator: true,
  },
  {
    label: 'Soluções para empresas',
    href: 'https://www.bellinatiperez.com.br/nossas-solucoes/',
    primary: true,  
  },
];

const bradescoMenuItems = [
  {
    label: 'Segurança',
    href: '#seguranca',
  },
  {
    label: 'Vantagens',
    href: '#vantagens',
  },
  {
    label: 'Como funciona',
    href: '#como-funciona',
  },
  {
    label: 'Dúvidas frequentes',
    href: '#duvidas-frequentes',
  },
  {
    label: 'Quem somos',
    href: '#quem-somos',
  },
];

function Header({ logged = false }) {
  const [open, setOpen] = useState(false);
  const { signOut } = useAuth();
  const FINANCEIRA = process.env.REACT_APP_FINANCEIRA;
  const isBradesco = !!(FINANCEIRA === 'bradesco');
  const headerMenuItems = isBradesco ? bradescoMenuItems : menuItems;

  return (
    <Wrapper isBradesco={!!isBradesco} logged={logged}>
      <Container>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          justifySelf="center"
          flexWrap="wrap"
        >
          {!isBradesco ? (
            <HeaderImage
              src={LogoHorizontal}
              alt="Logotipo da Bellinati Perez"
              onClick={() =>
                window.open('https://www.bellinatiperez.com.br/', '__blank')
              }
            />
          ) : (
            <HeaderImage
              isBradesco={isBradesco}
              src={BradescoBpLogo}
              alt="Logotipo do Bradesco"
              onClick={() => window.open('https://banco.bradesco/')}
            />
          )}
          <If condition={logged}>
            <Then>
              <Hidden smUp implementation="css">
                <LogoutButton onClick={signOut}>Sair</LogoutButton>
              </Hidden>
            </Then>
            <Else>
              <>
                <Hidden smDown implementation="css">
                  <HeaderLinkList>
                    {headerMenuItems.map((item, index) => (
                      <If key={index} condition={!!item.separator}>
                        <Then>
                          <Separator />
                        </Then>
                        <Else>
                          {isBradesco ? (
                            <BradescoHeaderLink
                              href={item.href}
                            >
                              {item.label}
                            </BradescoHeaderLink>
                          ) : (
                            <HeaderLink
                              color={item.primary && 'primary'}
                              href={item.href}
                            >
                              {item.label}
                            </HeaderLink>
                          )}
                        </Else>
                      </If>
                    ))}
                  </HeaderLinkList>
                </Hidden>
                <Hidden smUp mdUp implementation="css">
                  <IconButton size="small" onClick={() => setOpen(true)}>
                    {isBradesco ? (
                      <img style={{ width: 37 }} src={BradescoHambugerIcon} alt="Botão para mostrar menu" />
                    ) : (
                      <img src={HamburgerIcon} alt="Botão para mostrar menu" />
                    )}
                  </IconButton>
                </Hidden>
                <Drawer
                  anchor="right"
                  open={open}
                  onToggle={setOpen}
                  menuItems={headerMenuItems}
                />
              </>
            </Else>
          </If>
        </Box>
      </Container>
    </Wrapper>
  );
}

export default Header;
