import React from 'react';

import BackButton from 'components/BackButton';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import DocumentIcon from 'images/document-gray.svg';
import { Status, Label, Value } from 'components/DividaAcordoItem/styles';
import { formatToPercent, getFormattedDate, getMoney } from 'utils/transform';
import { FirmarAcordoButton } from './styles';
import { IDivida } from 'interfaces/Divida';
import { IOpcaoPagamento } from 'interfaces/BuscaOpcaoPagamento';
import { ICustomizacao } from 'interfaces/Customizacao';

interface IGenericResumoBoletoProps {
  history: any;
  custom:ICustomizacao;
  isDesktop: boolean;
  isMobile: boolean;
  divida: IDivida;
  parcelamento: IOpcaoPagamento;
  bank?: any;
  onEmitirBoleto: any;
  loadingEmitirBoleto: boolean;
}

const GenericResumoBoleto = ({
  history,
  custom,
  isDesktop,
  isMobile,
  divida,
  parcelamento,
  bank,
  onEmitirBoleto,
  loadingEmitirBoleto,
}: IGenericResumoBoletoProps) => {
  function mostrarValorDesconto() {
    return (divida?.exibirDescontoMonetario && !!parcelamento.descontoMonetario) 
    || (divida?.exibirDescontoPercentual && !!parcelamento.descontoPercentual);
  }

  return (
    <div>
      <BackButton
        onClick={() => {
          history.goBack();
        }}
      >
        Voltar para a home
      </BackButton>
      <Box mt={2} mb={8}>
        <Typography
          variant="h5"
          style={{
            fontWeight: 500,
          }}
        >
          Revise as informações abaixo e confirme o acordo com a melhor
          negociação
        </Typography>
      </Box>

      {!!parcelamento?.texto && (
        <Box mt={6} mb={3}>
          <Paper style={{}}>
            <Box p={3}>
              <Grid
                container
                direction="row"
                justifyContent={isMobile ? 'center' : 'space-between'}
                alignItems="center"
                spacing={2}
              >
                <Grid item container xs={12} md={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="caption"
                      align={isMobile ? 'center' : 'left'}
                      color="secondary"
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                      }}
                    >
                      {parcelamento.texto}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      )}

      <Box>
        <Paper>
          <Box width="100%" px={4} py={2}>
            <Box display="flex" alignItems="center">
              <Box mr={2}>
                <Status color="secondary">
                  <strong>Dívida negociada</strong>
                </Status>
              </Box>
              <Box mr={1}>
                <img
                  style={{ marginBottom: 2 }}
                  src={DocumentIcon}
                  alt="Documento"
                />
              </Box>
              <Status>
                <span>
                  {Number(divida?.contratos?.length)} Contrato
                  {Number(divida?.contratos?.length) > 1 && 's'}
                </span>
              </Status>
            </Box>
            <Box>
              <Typography style={{ fontSize: 28 }} variant="h6">
                <strong>{bank.financeira}</strong>
              </Typography>
            </Box>

            <Box mt={3}>
              <Grid container alignItems="center">
                <Grid item xs={6} sm={4}>
                  <Box>
                    <Label>Data da negociação:</Label>
                    <Value regular>
                      {getFormattedDate(new Date().toISOString())}
                    </Value>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                  {!!parcelamento?.valor && parcelamento?.valor !== 0 && (
                    <Box>
                      <Label>Valor do acordo</Label>
                      <Value regular>{getMoney(parcelamento.valorDivida)}</Value>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
            {divida?.contratos?.map((contrato, index) => (
              <Box key={contrato.documento} width="100%" mt={3}>
                <Grid container alignItems="center">
                  <Grid item xs={6} sm={4}>
                    {!!contrato?.produto && (
                      <Box>
                        <Label>Produto:</Label>
                        <Value>{contrato.produto}</Value>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    {!!contrato?.documento && (
                      <Box>
                        <Label>Contrato:</Label>
                        <Value>{contrato.documento}</Value>
                      </Box>
                    )}
                  </Grid>
                  {/* <Hidden smDown> */}
                  {!!contrato?.valorOriginalDivida && contrato?.valorOriginalDivida !== 0 && (
                    <Grid item xs={6} sm={5}>
                      {' '}
                      <Label
                        style={{
                          fontSize: 13,
                          textAlign: isDesktop ? 'right' : 'left',
                        }}
                      >
                        Valor original da dívida:
                      </Label>
                      <Value
                        style={{
                          fontSize: 13,
                          textAlign: isDesktop ? 'right' : 'left',
                        }}
                      >
                        {getMoney(contrato.valorOriginalDivida)}
                      </Value>{' '}
                    </Grid>
                  )}
                  <Grid item xs={6} sm={!!contrato?.valorOriginalDivida ? 12 : 5}>
                    {!!contrato?.valor && contrato?.valor !== 0 && (
                      <>
                        {' '}
                        <Label
                          style={{
                            fontSize: 13,
                            textAlign: isDesktop ? 'right' : 'left',
                          }}
                        >
                          {custom && (
                            custom.ResumoBoleto
                          )}
                        </Label>
                        <Value
                          style={{
                            fontSize: 13,
                            textAlign: isDesktop ? 'right' : 'left',
                          }}
                        >
                          {getMoney(contrato.valor)}
                        </Value>{' '}
                      </>
                    )}
                  </Grid>
                  {/* </Hidden> */}
                </Grid>
                {/* <Grid container>
                <Grid item xs={6} sm={3} />
                <When condition={!contrato.dataContratacao}>
                  <Grid item xs={6} sm={3} />
                </When>
                <Hidden smUp>
                  <Grid item xs={6} />
                  <Grid item xs={6}>
                    <Label>Valor do contrato:</Label>
                    <Value
                      style={{
                        fontSize: 13,
                        textAlign: isDesktop ? 'right' : 'left',
                      }}
                    >
                      {getMoney(contrato.valor)}
                    </Value>
                  </Grid>
                </Hidden>
              </Grid> */}
                {index + 1 !== Number(divida?.contratos?.length) && <Divider />}
              </Box>
            ))}

            <Box mt={3}>
              <Box mb={1}>
                <Typography variant="caption" style={{ color: grey[400] }}>
                  Pagamento
                </Typography>
              </Box>
              <Paper variant="outlined">
                <Box p={4}>
                  <Grid container spacing={2}>
                    <Grid item container xs={12} justifyContent="space-between" spacing={4}>
                      <Grid item xs={6} sm={6} >
                        <Typography paragraph style={{ fontWeight: 'bold' }}>
                          Pagamento
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{ fontWeight: 'normal', fontSize: 14 }}
                        >
                          {Number(parcelamento?.parcelas?.length) > 1
                            ? `Entrada + ${(Number(parcelamento.parcelas?.length) - 1)} parcela(s)`
                            : 'À vista'}
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{ fontWeight: 'normal', fontSize: 14 }}
                        >
                          {Number(parcelamento?.parcelas?.length) == 1 && (
                            !!parcelamento?.valorEntrada ? getMoney(parcelamento?.valorEntrada) 
                            : getMoney(parcelamento?.valor
                          ))}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        sm={6}
                        justifyContent={isDesktop ? 'flex-end' : 'flex-start'}
                      >
                        {!!mostrarValorDesconto() &&(
                          <Label
                            style={{
                              fontSize: 15,
                              fontWeight: 'bold',
                              textAlign: isDesktop ? 'right' : 'left',
                              marginTop: !isDesktop ? 10 : 0,
                            }}
                          >
                            {divida?.exibirDescontoPercentual && parcelamento.descontoPercentual && (
                              <>({formatToPercent(parcelamento.descontoPercentual)})</>
                            )}
                            {divida?.exibirDescontoMonetario && parcelamento.descontoMonetario && (
                              <> {getMoney(parcelamento.descontoMonetario)}</>
                            )} de desconto
                          </Label>
                        )}
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        {!!parcelamento?.valorEntrada &&
                          parcelamento?.valorParcela !== 0 && (
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems={isDesktop ? 'flex-start' : 'flex-start'}
                            >
                              <Label
                                style={{
                                  fontSize: 13,
                                  textAlign: isDesktop ? 'right' : 'left',
                                }}
                              >
                                Valor da entrada
                              </Label>
                              <Typography>
                                {getMoney(parcelamento.valorEntrada)}
                              </Typography>
                            </Box>
                          )}
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        {!!parcelamento?.parcelas && !!parcelamento?.parcelas[0]?.vencimento && (
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-end"
                          >
                            <Label style={{ fontSize: 13 }}>Vencimento</Label>
                            <Typography style={{ fontSize: 13 }}>
                              {getFormattedDate(
                                parcelamento.parcelas[0].vencimento,
                              )}
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                      <Grid
                        item
                        container
                        xs={6}
                        sm={6}
                        justifyContent="flex-start"
                      >
                      {!!parcelamento?.parcelas && !!parcelamento?.valorParcela && (
                          <Grid item xs={6} sm={6}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems={isDesktop ? 'flex-start' : 'flex-start'}
                            >
                              <Label 
                                style={{
                                  fontSize: 13,
                                  textAlign: isDesktop ? 'right' : 'left',
                                }}
                              >
                                  Valor da parcela
                              </Label>
                              <Typography>
                                {getMoney(parcelamento.valorParcela)}
                              </Typography>
                            </Box>
                          </Grid>
                      )}
                      </Grid>

                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>

            {/* <When condition={divida?.parcelas?.length > 1}>
            <Box mt={3}>
              <Grid container>
                <Grid item container xs={12} sm={10}>
                  <Grid item xs={6} sm={4}>
                    <Label>CET ao mês</Label>
                    <Value>{divida?.taxaJurosMensalCet}%</Value>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Label>CET ao ano</Label>
                    <Value>{divida?.taxaJurosAnualCet}%</Value>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Label>IOF</Label>
                    <Value>
                      {divida?.descricaoIof ||
                        `${divida?.valorIofPercentual}%`}
                    </Value>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Label>Taxa de juros ao mês</Label>
                    <Value>{divida?.taxaJurosMensalResumo}%</Value>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Label>Taxa de juros ao ano</Label>
                    <Value>{resumoBoleto.taxaJurosAnualResumo}%</Value>
                  </Grid>divida
                </Grid>
              </Grid>
            </Box>
          </When> */}

            <Box mt={3}>
              <FirmarAcordoButton
                onClick={() => onEmitirBoleto()}
                color="primary"
                variant="contained"
              >
                {!loadingEmitirBoleto ? (
                  'Confirmar Acordo'
                ) : (
                  <CircularProgress size={20} style={{ color: 'white' }} />
                )}
              </FirmarAcordoButton>
            </Box>

            {/* <When condition={parcelamento.parcelas?.length > 1}>
            <Box mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={11}>
                  <Label>
                    <strong>IOF:</strong> Imposto Operacional Financeiro
                  </Label>
                </Grid>
                <Grid item xs={12} sm={11}>
                  <Label>
                    <strong>CET:</strong> Custo Efetivo Total
                  </Label>
                </Grid>
              </Grid>
            </Box>
          </When> */}
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default GenericResumoBoleto;
