// Reference: https://gist.github.com/manzoorwanijk/5993a520f2ac7890c3b46f70f6818e0a

import { setIn } from 'final-form';

export const validate = (validationSchema: any) => async (values: any) => {
  try {
    await validationSchema.validate(values, { abortEarly: false });
  } catch (err: any) {
    const errors = err.inner.reduce((formError: any, innerError: any) => {
      return setIn(formError, innerError.path, innerError.message);
    }, {});

    return errors;
  }
};

export const validateValues = (validationSchema: any) => async (values: any) => {
  try {
    await validationSchema(values).validate(values, { abortEarly: false });
  } catch (err: any) {
    const errors = err.inner.reduce((formError: any, innerError: any) => {
      return setIn(formError, innerError.path, innerError.message);
    }, {});

    return errors;
  }
};
