import React from 'react';

import { Box, Container, Paper, Link } from '@material-ui/core';
import Instagram from 'images/instagram.svg';
import Facebook from 'images/facebook-dark.svg';
import styled from 'styled-components';
import Iso9001 from 'images/iso-9001.png';
import GPTWLogo from 'images/gptw_logo.svg';
import ItauLogo from 'images/itau-logo-parceria.svg';
import BradescoParceria from 'images/bradesco-logo-parceria.png';
import BradescoFooter from './BradescoFooter';

const Wrapper = styled(Paper).attrs({
  square: true,
  elevetion: 0,
})`
  padding: 20px 0;
  flex-shrink: 0;
`;

const FooterLink = styled(Link)`
  font-size: 12px;
  color: #333;
  text-decoration: none;

  ${props => props.theme.breakpoints.up('sm')} {
    & + & {
      margin-left: 46px;
    }
  }

  ${props => props.theme.breakpoints.down('sm')} {
    margin: 5px 10px;
  }
`;

const FooterLinkLight = styled(FooterLink)`
  color: rgba(0, 0, 0, 0.4);

  ${props => props.theme.breakpoints.up('sm')} {
    & + & {
      margin-left: 20px;
    }
  }

  ${props => props.theme.breakpoints.down('sm')} {
    margin: 5px 10px;
  }
`;

const SocialLink = styled(Link)`
  ${props => props.theme.breakpoints.up('sm')} {
    & + & {
      margin-left: 10px;
    }
  }

  ${props => props.theme.breakpoints.down('sm')} {
    margin: 0 5px;
  }
`;

const Inner = styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
@media (max-width: 600px){
    display: flex;
    flex-direction: column-reverse;
                  
  }
`;

const LinkList = styled.div`
  margin: auto;

  ${props => props.theme.breakpoints.down('sm')} {
    margin: 20px 0;
  }
`;

function Footer() {
  const FINANCEIRA = process.env.REACT_APP_FINANCEIRA;

  return (
    <>
      {FINANCEIRA === 'bradesco' ? (
        <BradescoFooter />
      ) : (
        <Wrapper>
          <Box display="flex" justifyContent="center" py={5}>
            <Container>
              <Inner
              display="flex"
              flexDirection="row-reverse">
                <img
                  src={GPTWLogo}
                  alt="Great Place To Work Logo"
                  style={{ height: 65 }}
                />
                <Box height={20} width={20} />
                <img
                  src={Iso9001}
                  alt="Certificado ISO 9001"
                  style={{ height: 65 }}
                />
                <LinkList>
                  <Box display="flex" justifyContent="center" flexWrap="wrap">
                    <FooterLinkLight href="https://www.bellinatiperez.com.br/a-bellinati-perez/">
                      A Bellinati Perez
                    </FooterLinkLight>
                    <FooterLinkLight href="https://www.bellinatiperez.com.br/nossas-solucoes/">
                      Nossas soluções
                    </FooterLinkLight>
                    <FooterLinkLight href="https://www.bellinatiperez.com.br/fale-com-a-gente/">
                      Fale Conosco
                    </FooterLinkLight>
                    <FooterLinkLight href="https://www.bellinatiperez.com.br/ouvidoria/">
                      Ouvidoria
                    </FooterLinkLight>
                    <FooterLinkLight href="https://www.bellinatiperez.com.br/carreira/">
                      Carreira
                    </FooterLinkLight>
                    <FooterLinkLight href="https://www.bellinatiperez.com.br/responsabilidade-social-corporativa/">
                      Responsabilidade Corporativa
                    </FooterLinkLight>
                  </Box>
                  <Box
                    mt={1}
                    width="100%"
                    display="flex"
                    justifyContent="start"
                    alignItems="center"
                    flexWrap="wrap"
                    marginBottom='20px'
                    marginTop='20px'
                    sx={{
                      '@media (max-width: 600px)': {
                        display: 'flex',
                        flexDirection: 'column-reverse',
                      }
                    }}
                  >
                    <FooterLink as="span">
                      © {new Date().getFullYear()} Todos os direitos reservados
                    </FooterLink>
                    <FooterLink href="https://www.bellinatiperez.com.br/politica-de-privacidade/">
                      Politica de privacidade
                    </FooterLink>
                  </Box>
                  <Box
                    sx={{
                      '@media (max-width: 600px)': {
                      display: 'flex',
                      justifyContent:'center',
                      alignItems:'center'
                      }
                    }}
                  >
                      <SocialLink href="https://www.facebook.com/bellinatiperez">
                        <img src={Instagram} alt="Instagram" />
                      </SocialLink>
                      <SocialLink href="https://www.instagram.com/bellinatiperez/">
                        <img src={Facebook} alt="Facebook" />
                      </SocialLink>
                  </Box>
                </LinkList>
                {FINANCEIRA === 'itau' && (
                  <img src={ItauLogo} alt="Itaú Parceria" style={{ height: 50, width: 280, objectFit: 'cover' }} />
                )}
                {FINANCEIRA === 'bradesco' && (
                  <img
                    src={BradescoParceria}
                    alt="Bradesco Parceria"
                    style={{ height: 35, margin: '25px 0 0 20px' }}
                  />
                )}
              </Inner>
            </Container>
          </Box>
        </Wrapper>
      )}
    </>
  );
}

export default Footer;
