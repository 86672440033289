import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt';

import GlobalStyle, { WhatsAppFloatButton } from './styles/global';
import './styles/App.css';
import theme from './config/theme';
import Routes from './routes';
import { AuthProvider } from './contexts/auth';
import { analyticsInit, trackingPageView } from './config/googleAnalytics';
declare global {
  interface Window {
    my_global_var: string;
  }
};

const App: React.FC = () => {
  useEffect(() => {
    analyticsInit();
    trackingPageView();
  }, []);

  return (
    <AuthProvider>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
              <Routes />
              <GlobalStyle />
              <ToastContainer autoClose={10000} />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </AuthProvider>
  );
};

export default App;
