import MUIButton from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const LinkButton = styled(MUIButton)`
  text-align: left;
  text-transform: none;
  height: 60px;
  display: flex;
  justify-content: flex-start;
`;

export const SubmitButton = styled(MUIButton)`
  text-transform: none;
  font-weight: bold;
  font-size: 1.15rem;
`;

export const CustomMessageLabel = styled(Typography)`
  color: #606060;
  margin-bottom: 10px;
`;