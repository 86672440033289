import React from 'react';
import Component from '@material-ui/core/Chip';
import styled from 'styled-components';

const Container = styled(Component).withConfig({
  shouldForwardProp: prop => !['color'].includes(prop),
})`
  background-color: ${props =>
    props.theme.palette.chips[props.color].background};
  color: ${props => props.theme.palette.chips[props.color].color};
  font-weight: 500;
`;

function Chip(props) {
  return <Container {...props} />;
}

export default Chip;
