import React from 'react';
import DialogForm from 'components/DialogForm';
import { Typography, Button, CircularProgress } from '@material-ui/core';
import { ContainerBottom } from './style';
import { ICustomizacao } from 'interfaces/Customizacao';
import parse from 'html-react-parser'
import { Else, If, Then } from 'react-if';
import Download from 'images/download.svg';

interface IRecoveryDialogProps {
  open: boolean;
  loadingBaixarBoleto: boolean;
  onDownload: () => void;
  onClose: () => void;
  custom: ICustomizacao | undefined;
}

const RecoveryDialog = ({
  open,
  loadingBaixarBoleto,
  onDownload,
  onClose,
  custom
}: IRecoveryDialogProps) => {

  const onDownloadClose =async () => {
    await onDownload();
    onClose();
  }

  return (
    <DialogForm
      id="recovery_form"
      title="Informações importantes"
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      {() => (
        <>
          <Typography style={{fontSize: 14, marginBottom: 80}}>
            {parse(custom?.Recovery || "")}
          </Typography>
            <ContainerBottom my={2}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<img src={Download} alt="Download" />}
                disabled={loadingBaixarBoleto}
                onClick={onDownloadClose}
              >
                <If condition={loadingBaixarBoleto}>
                  <Then>
                    <CircularProgress size={15} />
                  </Then>
                  <Else>Baixar Boleto</Else>
                </If>
              </Button>
              {/* <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={onClose}
                size="small"
              >
                Baixar Boleto
              </Button> */}
            </ContainerBottom>
        </>
      )}
    </DialogForm>
  );
}

export default RecoveryDialog;