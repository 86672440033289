import React, { useEffect, useState } from 'react';

import BackButton from 'components/BackButton';
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  useMediaQuery,
} from '@material-ui/core';
import Copy from 'images/copy.svg';
import Sucesso from 'images/sucesso.svg';
import Download from 'images/download.svg';
import { format } from 'utils/form';
import { decryptData } from 'utils/crypto';
import { When, Else, If, Then } from 'react-if';
import { InfoText, Label, Value, LabelLimit } from './styles';
import { getFormattedDate, getMoney } from 'utils/transform';

import { useTheme } from 'styled-components';
// import { AnyNsRecord } from 'dns';
import { IOpcaoPagamento } from 'interfaces/BuscaOpcaoPagamento';
import { IBoleto } from 'interfaces/Boleto';
import { ICustomizacao } from 'interfaces/Customizacao';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import ContatoDialog from '../../dialogs/ContatoDialog';

const PHONE_MASK = '(99) 99999-9999';

interface IBradescoReorgDividaDetalheProps {
  carteiraId: number;
  crmId: any;
  custom: ICustomizacao;
  history: any;
  boleto: IBoleto;
  parcelamento: IOpcaoPagamento;
  whatsapp: string;
  setWhatsapp: any;
  email: string;
  setEmail: any;
  telefone: string;
  setTelefone: any;
  showWhatsApp: boolean;
  loadingBaixarBoleto: boolean;
  loadingEnviarBoleto: boolean;
  onClickBaixarBoleto: any;
  enviarBoletos: any;
  copyToClipboard: any;
  mostraCamposBoletoEmailTelefone: boolean;
}

const BradescoReorgDividaDetalhe = ({
  carteiraId,
  crmId,
  custom,
  history,
  boleto,
  parcelamento,
  whatsapp,
  setWhatsapp,
  email,
  setEmail,
  telefone,
  setTelefone,
  showWhatsApp,
  loadingBaixarBoleto,
  loadingEnviarBoleto,
  onClickBaixarBoleto,
  enviarBoletos,
  copyToClipboard,
  mostraCamposBoletoEmailTelefone,
}: IBradescoReorgDividaDetalheProps) => {
  const theme: any = useTheme();
  const isMobile = useMediaQuery((th: any) => th?.breakpoints?.down('sm'));
  const [dividaParcelada, setDividaParecelada] = useState(null);

  const [open, setOpen] = useState(false);
  const [carteiras, setCarteiras] = useState([{crmId: crmId, carteiraId: carteiraId, fase:null, ranking:null}]);
  const isDesktop = useMediaQuery((theme: any) => theme?.breakpoints?.up('sm'));

  const carteiraIds = [carteiraId];

  useEffect(() => {
    const parcelado = localStorage.getItem('@resumoBoletoParcelamento');

    if (parcelado !== null && parcelado !== undefined) {
      setDividaParecelada(decryptData(parcelado));
    } else {
      setDividaParecelada(null);
    }
  }, []);
  return (
    <div>
      <BackButton
        onClick={() => {
          localStorage.removeItem('@dividaSucesso');
          localStorage.removeItem('@retornoEmitirBoleto');
          history.push('/');
        }}
      >
        Voltar para Home
      </BackButton>
      <Box mt={2}>
        <Paper
          style={{
            padding: '16px 32px'
          }}
        >
          <Box 
            style={{
              background: '#D9F5D8',
              display: 'flex',
              flexDirection: 'row',
          }}>
            <Box 
              style={{
                color: '#43B369',
                padding: '15px 0 15px 15px'
              }}>
              <CheckCircleIcon  />
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 15
              }}
            >
              <Typography
                style={{color: '#3e3e3e'}}
              >
                <strong>Operação concluída</strong>
              </Typography>
              <InfoText>
                Parabéns, seu acordo foi feito com sucesso!
              </InfoText>
              <InfoText>
                O número da sua reorganização é <strong>{boleto.acordoId}</strong>
              </InfoText>
            </Box>
          </Box>
          <Box mt={3}>
            <Box>
              <LabelLimit style={{marginBottom: 5}}>
                Dados da solicitação
              </LabelLimit>
              <Paper variant="outlined" style={{padding: '15px'}}>
                <Grid container >
                  <Grid item xs={6} sm={4}>
                    <Label>
                      Valor dos contratos selecionados
                    </Label>
                    <Value>
                      {getMoney(parcelamento.valor)}
                    </Value>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Label>
                      Valor das parcelas
                    </Label>
                    <Value>
                      {getMoney(parcelamento.valorParcela)}
                    </Value>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Label>
                      IOF total (imposto)
                    </Label>
                    <Value>
                      {getMoney(boleto.valorIof)}
                    </Value>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Label>
                      Vencimento da primeira parcela
                    </Label>
                    <Value>
                      {getFormattedDate(parcelamento.dataVencimento)}
                    </Value>
                  </Grid>
                </Grid>
              </Paper>
            </Box>      
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default BradescoReorgDividaDetalhe;
