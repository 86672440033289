import React, { useEffect, useState } from 'react';

import BackButton from 'components/BackButton';
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  useMediaQuery,
} from '@material-ui/core';
import Copy from 'images/copy.svg';
import Sucesso from 'images/sucesso.svg';
import Download from 'images/download.svg';
import { format } from 'utils/form';
import { decryptData } from 'utils/crypto';
import { When, Else, If, Then } from 'react-if';
import { Label, Value } from 'components/DividaAcordoItem/styles';

import { getFormattedDate, getMoney } from 'utils/transform';
import { useTheme } from 'styled-components';
// import { AnyNsRecord } from 'dns';
import { IOpcaoPagamento } from 'interfaces/BuscaOpcaoPagamento';
import { IBoleto } from 'interfaces/Boleto';
import { ICustomizacao } from 'interfaces/Customizacao';

import ContatoDialog from '../../dialogs/ContatoDialog/';

const PHONE_MASK = '(99) 99999-9999';

interface IBradescoDIvidaDetalheProps {
  carteiraId: number;
  crmId: any;
  custom:ICustomizacao;
  history: any;
  boleto: IBoleto;
  parcelamento: IOpcaoPagamento;
  whatsapp: string;
  setWhatsapp: any;
  email: string;
  setEmail: any;
  telefone: string;
  setTelefone: any;
  showWhatsApp: boolean;
  loadingBaixarBoleto: boolean;
  loadingEnviarBoleto: boolean;
  onClickBaixarBoleto: any;
  enviarBoletos: any;
  copyToClipboard: any;
  mostraCamposBoletoEmailTelefone: boolean;
  downloadAgenda: any;
}

const BradescoDividaDetalhe = ({
  carteiraId,
  crmId,
  custom,
  history,
  boleto,
  parcelamento,
  whatsapp,
  setWhatsapp,
  email,
  setEmail,
  telefone,
  setTelefone,
  showWhatsApp,
  loadingBaixarBoleto,
  loadingEnviarBoleto,
  onClickBaixarBoleto,
  enviarBoletos,
  copyToClipboard,
  mostraCamposBoletoEmailTelefone,
  downloadAgenda,
}: IBradescoDIvidaDetalheProps) => {
  const theme: any = useTheme();
  const isMobile = useMediaQuery((th: any) => th?.breakpoints?.down('sm'));
  const [dividaParcelada, setDividaParecelada] = useState(null);

  const [open, setOpen] = useState(false);
  const [carteiras, setCarteiras] = useState([{crmId: crmId, carteiraId: carteiraId, fase:null, ranking:null}]);
  const carteiraIds = [carteiraId];

  useEffect(() => {
    const parcelado = localStorage.getItem('@resumoBoletoParcelamento');

    if (parcelado !== null && parcelado !== undefined) {
      setDividaParecelada(decryptData(parcelado));
    } else {
      setDividaParecelada(null);
    }
  }, []);
  return (
    <div>
      <BackButton
        onClick={() => {
          localStorage.removeItem('@dividaSucesso');
          localStorage.removeItem('@retornoEmitirBoleto');
          history.push('/');
        }}
      >
        Voltar para Home
      </BackButton>
      <Box mt={2} mb={8}>
        <Typography
          variant="h5"
          style={{
            fontWeight: 500,
          }}
        >
          Parabéns, seu acordo foi feito com sucesso!
        </Typography>
        <Typography
          variant="h5"
          style={{
            fontWeight: 500,
          }}
        >
          Agora você só precisa fazer o pagamento
        </Typography>
      </Box>

      {!!parcelamento?.texto && (
        <Box mt={6} mb={6}>
          <Paper style={{}}>
            <Box p={3}>
              <Grid
                container
                direction="row"
                justifyContent={isMobile ? 'center' : 'space-between'}
                alignItems="center"
                spacing={2}
              >
                <Grid item container xs={12} md={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="caption"
                      align={isMobile ? 'center' : 'left'}
                      color="secondary"
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                      }}
                    >
                      {parcelamento.texto}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      )}

      <Box>
        <Paper>
          <Box px={3}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={4}
            >
              <Grid item xs={12} sm={3}>
                <Typography
                  variant="h6"
                  align={(isMobile && 'center') || 'left'}
                  style={{
                    fontWeight: 'normal',
                    fontSize: 14,
                  }}
                >
                  {Number(parcelamento?.parcelas?.length) > 1
                    ? '1º Pagamento'
                    : 'À vista'}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Box
                  flexDirection="column"
                  display="flex"
                  width="100%"
                  alignItems="center"
                >
                  {(!!parcelamento?.dataVencimento || dividaParcelada) && (
                    <>
                      {' '}
                      <Typography
                        variant="body1"
                        style={{ textAlign: 'center', fontSize: 14 }}
                      >
                        Vencimento
                      </Typography>
                      <strong style={{ fontSize: 14 }}>
                        {getFormattedDate(parcelamento.dataVencimento)}
                      </strong>
                    </>
                  )}
                </Box>
              </Grid>
              <When condition={Number(parcelamento?.parcelas?.length) > 1}>
                <Grid item xs={6} sm={3}>
                  <Box
                    flexDirection="column"
                    display="flex"
                    width="100%"
                    alignItems="center"
                  >
                    {(!!parcelamento?.parcelas?.length || dividaParcelada) && (
                      <>
                        {' '}
                        <Typography
                          variant="body1"
                          style={{ textAlign: 'center', fontSize: 14 }}
                        >
                          Número de parcelas
                        </Typography>
                        <strong style={{ fontSize: 14 }}>
                          {parcelamento.parcelas?.length}
                        </strong>
                      </>
                    )}
                  </Box>
                </Grid>
              </When>
              {parcelamento.parcelas?.length === 1 && (
                <Grid item xs={6} sm={3} />
              )}
              <Grid item xs={6} sm={3}>
                <Box
                  alignItems={isMobile ? 'flex-start' : 'flex-end'}
                  flexDirection="column"
                  display="flex"
                  width="100%"
                >
                  {!!boleto?.valorTotal && boleto?.valorTotal !== 0 && (
                    <>
                      {' '}
                      <Typography
                        variant="body1"
                        style={{ textAlign: 'center', fontSize: 14 }}
                      >
                        Valor
                      </Typography>
                      <strong style={{ textAlign: 'center', fontSize: 14 }}>
                        {getMoney(boleto.valorTotal)}
                      </strong>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>

      <When condition={Number(parcelamento?.parcelas?.length) > 1}>
        <Box mt={4}>
          <Paper>
            <Box px={3} py={2}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
              >
                {!!boleto?.taxaJurosMensalCet && (
                  <Grid item xs={6} sm={2}>
                    <Label align={(isMobile && 'center') || 'left'}>
                      CET ao mês
                    </Label>
                    <Value
                      align={(isMobile && 'center') || 'left'}
                      style={{ fontSize: '0.6em' }}
                    >
                      {`${boleto?.taxaJurosMensalCet}%`}
                    </Value>
                  </Grid>
                )}
                {!!boleto?.taxaJurosAnualCet && (
                  <Grid item xs={6} sm={2}>
                    <Label align="center">CET ao ano</Label>
                    <Value align="center" style={{ fontSize: '0.6em' }}>
                    {`${boleto?.taxaJurosAnualCet}%`}
                    </Value>
                  </Grid>
                )}
                {(!!boleto?.descricaoIof || !!boleto?.valorIofPercentual) && (
                  <Grid item xs={6} sm={3}>
                    <Label align="center">IOF</Label>
                    <Value align="center" style={{ fontSize: '0.6em' }}>
                      {(!!boleto?.descricaoIof && boleto?.descricaoIof) ||
                        `${!!boleto?.valorIofPercentual && boleto?.valorIofPercentual}%`}
                    </Value>
                  </Grid>
                )}
                {!!boleto?.taxaJurosMensalResumo && (
                  <Grid item xs={6} sm={2}>
                    <Label align="center">Taxa de juros ao mês</Label>
                    <Value align="center" style={{ fontSize: '0.6em' }}>
                      {boleto.taxaJurosMensalResumo}
                      %
                    </Value>
                  </Grid>
                )}
                {!!boleto?.taxaJurosAnualResumo && (
                  <Grid item xs={6} sm={3}>
                    <Label align={(isMobile && 'center') || 'right'}>
                      Taxa de juros ao ano
                    </Label>
                    <Value
                      align={(isMobile && 'center') || 'right'}
                      style={{ fontSize: '0.6em' }}
                    >
                      {boleto.taxaJurosAnualResumo}
                      %
                    </Value>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Paper>
        </Box>
      </When>

      {/* <When condition={parcelamento.parcelas?.length > 1}>
      <Box mt={4}>
        <Paper>
          <Box px={3}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={4}
            >
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: 'normal',
                  }}
                >
                  Demais parcelas
                </Typography>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Box
                  flexDirection="column"
                  display="flex"
                  width="100%"
                  alignItems="center"
                >
                  <Typography variant="body1">Vencimento</Typography>
                  <strong>
                    {resumoBoleto.diaVencimentoDemaisParcelas}
                  </strong>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Box
                  flexDirection="column"
                  display="flex"
                  alignItems="center"
                  width="100%"
                >
                  <Typography
                    variant="body1"
                    style={{ textAlign: 'center' }}
                  >
                    Forma de pagamento
                  </Typography>
                  <strong>
                    {resumoBoleto.formaPagamentoDemaisParcelas}
                  </strong>
                </Box>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Box
                  alignItems={isMobile ? 'flex-start' : 'flex-end'}
                  flexDirection="column"
                  display="flex"
                  width="100%"
                >
                  <Typography variant="body1">Valor Total</Typography>
                  <strong>{getMoney(divida.valorDivida)}</strong>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </When> */}

      {!!mostraCamposBoletoEmailTelefone && (
        <>
          <Box mt={4}>
            <Paper>
              <Box px={3} py={2}>
                <Grid
                  container
                  direction={isMobile ? 'column' : 'row'}
                  justifyContent="center"
                  alignItems={isMobile ? 'stretch' : 'center'}
                  spacing={2}
                >
                  <Grid item xs>
                    {!!boleto?.linhaDigitavel && (
                      <>
                        {' '}
                        <Typography variant="body2" style={{ fontSize: 14 }}>
                          Código de Barras
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{
                            fontSize: 12,
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          <strong style={{ fontSize: 14 }}>
                            {boleto.linhaDigitavel}
                          </strong>
                        </Typography>
                      </>
                    )}
                  </Grid>

                  <Grid item>
                    <Box width="100%">
                      <Grid
                        container
                        wrap="nowrap"
                        direction={isMobile ? 'column' : 'row'}
                        justifyContent="flex-end"
                        spacing={2}
                      >
                        <Grid item>
                          {!!boleto?.linhaDigitavel && (
                            <Button
                              onClick={() => {
                                copyToClipboard(boleto.linhaDigitavel);
                              }}
                              variant="contained"
                              fullWidth={isMobile}
                              color="primary"
                              startIcon={<img src={Copy} alt="Copy" />}
                            >
                              Copiar Código
                            </Button>
                          )}
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            fullWidth={isMobile}
                            color="primary"
                            startIcon={<img src={Download} alt="Download" />}
                            disabled={loadingBaixarBoleto}
                            onClick={onClickBaixarBoleto}
                          >
                            <If condition={loadingBaixarBoleto}>
                              <Then>
                                <CircularProgress size={15} />
                              </Then>
                              <Else>Baixar Boleto</Else>
                            </If>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>

          <Box mt={2}>
            <Paper>
              <Box px={3} py={2}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="email"
                      label="E-mail"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      value={email}
                      onChange={event => setEmail(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => enviarBoletos(1)}
                        fullWidth={isMobile}
                        disabled={loadingEnviarBoleto}
                      >
                        <If condition={loadingEnviarBoleto}>
                          <Then>
                            <CircularProgress size={15} />
                          </Then>
                          <Else>Enviar E-Mail</Else>
                        </If>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>

          <Box mt={2}>
            <Paper>
              <Box py={2} px={3}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="telefone"
                      // maxLength="14"
                      label="Telefone"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={format(PHONE_MASK).format(telefone)}
                      onChange={event => setTelefone(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => enviarBoletos(2)}
                        fullWidth={isMobile}
                        disabled={loadingEnviarBoleto}
                      >
                        <If condition={loadingEnviarBoleto}>
                          <Then>
                            <CircularProgress size={15} />
                          </Then>
                          <Else>Enviar por SMS</Else>
                        </If>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>

          {Number(parcelamento?.parcelas?.length) > 1 && (
          <Box mt={2}>
            <Paper>
              <Box py={2} px={3}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={9}>
                    <Typography>
                      Agende as próximas parcelas em seu calendário!
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={downloadAgenda}
                        fullWidth={isMobile}
                        disabled={loadingEnviarBoleto}
                      >
                        <If condition={loadingEnviarBoleto}>
                          <Then>
                            <CircularProgress size={15} />
                          </Then>
                          <Else>Baixar Agenda</Else>
                        </If>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
          )}

          <When condition={showWhatsApp}>
            <Box mt={2}>
              <Paper>
                <Box p={2}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="whatsapp"
                        label="Whatsapp"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={format(PHONE_MASK).format(whatsapp)}
                        onChange={event => setWhatsapp(event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => enviarBoletos(3)}
                          fullWidth={isMobile}
                          disabled={loadingEnviarBoleto}
                        >
                          <If condition={loadingEnviarBoleto}>
                            <Then>
                              <CircularProgress size={15} />
                            </Then>
                            <Else>Enviar por WhatsApp</Else>
                          </If>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>
          </When>
        </>
      )}

      {!mostraCamposBoletoEmailTelefone && (
        <Box>
          <Box mt={6}>
            <Paper style={{}}>
              <Box p={3}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    
                      <Typography
                        gutterBottom
                        style={{ fontSize: 12, fontWeight: 500, maxWidth: 450, color: 'red' }}
                      >
                        Duvidas entrar em contato com nossos canais de atendimento:
                      </Typography>
                    
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box style={{ display: "flex", justifyContent: isMobile ? "flex-start" : "flex-end", marginTop: isMobile ? "6px" : "0" }}>
                      
                        <Button
                          style={{ color: '#fff', whiteSpace: 'nowrap' }}
                          color="secondary"
                          variant="contained"
                          onClick={() => setOpen(true)}
                        >
                          Canais de atendimento
                        </Button>
                      
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
        </Box>
      )}

      <ContatoDialog
        onClose={() => setOpen(false)}
        open={open}
        carteiraIds={carteiraIds}
        carteiras={carteiras}
        initialCateriaId={null}
      />

      <Box mt={6}>
        <Paper
          style={{
            background: theme?.palette?.primary?.main,
          }}
        >
          <Box p={3}>
            <Grid
              container
              direction="row"
              justifyContent={isMobile ? 'center' : 'space-between'}
              alignItems="center"
              spacing={2}
            >
              <Grid item container xs={12} md={9} spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    align={isMobile ? 'center' : 'left'}
                    style={{
                      color: theme?.palette?.primary?.contrastText,
                    }}
                  >
                    Ao prosseguir com a negociação, seus dados serão registrados
                    no sistema de informação de crédito do Banco Central, e a
                    instituição financeira poderá consultar os seus dados.
                  </Typography>
                </Grid>
                <When condition={Number(parcelamento?.parcelas?.length) > 1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="caption"
                      align={isMobile ? 'center' : 'left'}
                      style={{
                        color: theme?.palette?.primary?.contrastText,
                      }}
                    >
                      Após o pagamento da primeira parcela, dentro de 18 dias
                      você receberá um kit de contratação com toda a descrição
                      desta negociação.
                    </Typography>
                  </Grid>
                </When>
                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    align={isMobile ? 'center' : 'left'}
                    style={{
                      color: theme?.palette?.primary?.contrastText,
                    }}
                  >
                    {custom && (
                        custom.DividaDetalhe
                      )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    align={isMobile ? 'center' : 'left'}
                    style={{
                      color: theme?.palette?.primary?.contrastText,
                    }}
                  >
                    {custom && (
                        custom.DividaDetalheApos
                      )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    align={isMobile ? 'center' : 'left'}
                    style={{
                      color: theme?.palette?.primary?.contrastText,
                    }}
                  >
                    O não pagamento pode acarretar em cancelamento do acordo e
                    perda do desconto ofertado.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    align={isMobile ? 'center' : 'left'}
                    style={{
                      color: theme?.palette?.primary?.contrastText,
                    }}
                  >
                    Você pode pagar em qualquer instituição financeira.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <img
                  style={{
                    marginBottom: -8,
                    marginLeft: 'auto',
                    marginRight: isMobile ? 'auto' : 0,
                    display: 'block',
                  }}
                  src={Sucesso}
                  alt="Mulher demonstrando tranquilidade"
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default BradescoDividaDetalhe;
