import React, { useState } from 'react';
import DialogForm from 'components/DialogForm';
import { Typography, Button, Box, TextField } from '@material-ui/core';
import { ContainerBottom } from './style';
import { encryptData } from 'utils/crypto';
import { useHistory } from 'react-router-dom';
import { Else, If, Then } from 'react-if';
import { useAuth } from 'contexts/auth';
import { normalizeText } from 'utils/normalizeText';

interface IValidateNameDialogProps {
	open: boolean;
	onClose: () => void;
	setErrorMessage: any;
	errorMessage: string;
	financeira: string;
}

const ValidateNameDialog = ({
	open,
	onClose,
	setErrorMessage,
	errorMessage,
	financeira
}: IValidateNameDialogProps) => {
	const history = useHistory();
	const { user, bank } = useAuth();
	const [nomeInput, setNome] = useState("");
	const [nomeMaeInput, setNomeMae] = useState("");
	const [showmessage, setShowmessage] = useState(false);
	let buttonText = financeira === "bradesco" ? "Confirmar" : "Validar nome";
	let dialogTitle = financeira === "bradesco" ? "" : "Digite seu nome para continuar";

	if (!!user?.nomeMae && !(financeira === "bradesco")) {
		buttonText = "Validar nomes";
		dialogTitle = "Valide as seguintes informações para continuar";
	}

	const onValidateClose = () => {
		setShowmessage(false);
		const nomeCrmNormalized = normalizeText(user?.nome ?? '');
		const nomeInputNormalized = normalizeText(nomeInput);

		const nomeMaeCrmNormalized = normalizeText(user?.nomeMae ?? '');
		const nomeMaeInputNormalized = normalizeText(nomeMaeInput);

		if (nomeInputNormalized === nomeCrmNormalized &&
			(nomeMaeInputNormalized === nomeMaeCrmNormalized || !user?.nomeMae)) {
			localStorage.setItem('@bank', encryptData(bank));
			onClose();
			history.push('/');
		}
		else {
			setShowmessage(true);
			setErrorMessage("Os nomes fornecidos não parecem ser válidos. Certifique-se de que os nomes estão corretos e tente novamente.")
		}
	}

	const onValidateCloseBradesco = () => {
		setShowmessage(false);
		const nomeMaeCrmNormalized = normalizeText(user?.nomeMae ?? '');
		const nomeMaeInputNormalized = normalizeText(nomeMaeInput);

		if (nomeMaeInputNormalized === nomeMaeCrmNormalized) {
			localStorage.setItem('@bank', encryptData(bank));
			onClose();
			history.push('/');
		}
		else {
			setShowmessage(true);
			setErrorMessage("Os nome fornecido não parece ser válido. Certifique-se de que o nome está correto e tente novamente.")
		}
	}

	const exibirTextFieldMae = () => {
		if (!!user?.nomeMae) {
			return (
				<TextField
					name="nomeMaeInput"
					id="nome-mae-input"
					InputLabelProps={{
						shrink: true,
					}}
					label="Digite o nome completo da sua mãe"
					margin="normal"
					fullWidth
					variant="outlined"
					onChange={e => setNomeMae(e.target.value)}
				/>
			)
		}
	}

	return (
		<DialogForm
			id="validate_name_form"
			title={dialogTitle}
			open={open}
			onClose={onClose}
			maxWidth="xs"
			windowed={true}
			financeira={financeira}
		>
			{() => (
				<>
					<Box>
						<ContainerBottom>
							<Box
								display="flex"
								flexDirection="column"
								justifyContent="center"
								alignItems="center"
								style={{ padding: "0 25px" }}
							>
								<If condition={financeira === "bradesco"}>
									<Then>
										<Typography
											style={{
												fontFamily: "Bradesco-Sans-Regular",
												fontSize: 16,
												color: "#4D4E53",
												marginBottom: 10
											}}
										>
											Para sua segurança, confirme o nome da sua mãe:
										</Typography>
									</Then>
								</If>

								<Typography
									style=
									{{
										fontSize: 12,
										color: "red",
										fontFamily: financeira === "bradesco" ? "Bradesco-Sans-Regular" : "Roboto, sans-serif"
									}}
								>
									<If condition={showmessage}>
										<Then>
											{errorMessage}
										</Then>
									</If>
								</Typography>

								{financeira !== "bradesco" && (
									<TextField
										name="nome"
										id="nome-input"
										InputLabelProps={{
											shrink: true,
										}}
										label="Digite seu nome completo"
										margin="normal"
										fullWidth
										variant="outlined"
										onChange={e => setNome(e.target.value)}
									/>
								)}

								{exibirTextFieldMae()}


								{financeira === "bradesco" ? (
									<Button
										variant="contained"
										color="primary"
										fullWidth
										style={{ height: 56, margin: '15px 0', borderRadius: 100 }}
										onClick={onValidateCloseBradesco}
									>
										{buttonText}
									</Button>
								) : (
									<Button
										variant="contained"
										color="primary"
										style={{ height: '42px', marginTop: '15px' }}
										onClick={onValidateClose}
									>
										{buttonText}
									</Button>
								)}
							</Box>
						</ContainerBottom>
					</Box>
				</>
			)}
		</DialogForm>
	);
}

export default ValidateNameDialog;