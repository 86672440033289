import styled, { css } from 'styled-components';
import Typography, { TypographyProps }  from '@material-ui/core/Typography';

// interface ValueProps extends TypographyProps {
//   regular?: boolean;
//   noMargin?: boolean;
// }

export const InfoText = styled(Typography)`
  color: #5c5c5c;
  font-size: 15px;
  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 13px;
  }
`;

export const Label = styled(Typography)`
  color: #b2b2b2;
  margin-bottom: 8px;
  font-size: 12px;
`;

export const LabelLimit = styled(Typography)`
  font-size: 12px;
  color: #6e6e6e;
  margin-top: 5px
`;

interface ValueProps extends TypographyProps {
  regular?: boolean;
  noMargin?: boolean;
}

const ValueModel = styled(Typography)<ValueProps>``;

export const Value = styled(ValueModel).withConfig({
  shouldForwardProp: (prop: any) => !['regular', 'noMargin'].includes(prop),
})`
  font-size: ${props => (props?.regular ? 14 : 12)}px;
  font-weight: ${props => (props?.regular ? 400 : 600)};
  margin-bottom: 16px;

  ${props =>
    props?.regular &&
    css`
      margin-top: -4px;
    `}

  ${props =>
    props?.noMargin &&
    css`
      margin-bottom: 0;
    `}
`;
