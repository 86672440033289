import { format, parseISO } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';
import numeral from 'numeral';
import 'numeral/locales/pt-br';

numeral.locale('pt-br');

// "code" can be: https://date-fns.org/v2.16.1/docs/format
// example (display date & time):
// getFormattedDate(new Date().toISOString(), 'Pp')
export function getFormattedDate(date: any, code = 'P') {
  if (!date) return '';
  return format(parseISO(date), code, { locale });
}

export function getMoney(number: any) {
  if (isNaN(number)) return '';
    // return numeral(number || 0).format('$ 0,0[.]00');
  return numeral(number || 0).format('$ 0,0.00');
}
export function formatCurrencyIntoNumber(value: string) {
  return Number(value.replace('.', '').replace(',', '.'));
}

export function moneyAndPercent(number: any,number2: any) {
  return [
    (numeral(number || 0).format('$ 0,0.00')),
    (new Intl.NumberFormat('pt-BR', {style: 'percent', minimumFractionDigits: 2,}).format(number2 / 100))
  ]
}

export function getPercentage(number: any) {
  if (isNaN(number)) return '';
  return numeral((number || 0) / 100).format('0[.]00 %');
}

export function formatToPercent(number: any) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 2,
  }).format(number / 100);
}

export const isReal = (value: any) => {
  var valor = value;

  try {
    valor = valor + '';
    valor = valor.replace(/[\D]+/g, '');
    valor = valor + '';
    valor = valor.replace(/([0-9]{2})$/g, ',$1');

    if (valor.length > 6)
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');

    valor = valor.replace(/^0+(?!,|$)/, '');

    if(valor.length === 3) {
      valor = '0' + valor;
    }

    return valor;
  } catch {
    return valor;
  }
};

export const ClearString = (value: any) => {
  let valueModify = value.replace(/\D/g, '');
  return valueModify;
};
