import { Box, Grid } from "@material-ui/core";
import styled from "styled-components";

export const BradescoFooterContainer = styled.footer`
  background-color: #fff;
  width: 100vw;
`;

export const BradescoFooterContent = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  font-family: 'Bradesco-Sans-Regular';
  font-size: 14px;

  @media (max-width: 1280px) {
    margin: 0 50px;
  }

  a {
    color: #4D4E53;
    text-decoration: underline;
  }

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
    text-align: center;
  }
`;

export const BpCertContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 776px) {
    margin-bottom: 10px;
    flex-direction: column;
  }
`;

export const BpIsoContainer = styled(Box)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  @media (max-width: 1000px) {
    margin-bottom: 10px;
  }

  a {
    display: flex;
    text-decoration: none;
  }
`;

export const BpCertText = styled(Box)`
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: #E3000F4D;
  border-radius: 0px 5px 5px 0px;
  padding: 6.7px 6px;
  font-size: 10px;
  font-family: 'Bradesco-Sans-Bold';
  text-align: center;
  color: #767676;
`;