import styled, { css } from 'styled-components';
import Typography, { TypographyProps }  from '@material-ui/core/Typography';

// interface ValueProps extends TypographyProps {
//   regular?: boolean;
//   noMargin?: boolean;
// }

export const Status = styled(Typography)`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

export const Label = styled(Typography)`
  color: #b2b2b2;
  margin-bottom: 8px;
  font-size: 12px;
`;

export const LabelLimit = styled(Typography)`
  font-size: 12px;
  color: #424242;
  margin-top: 5px
`;

interface ValueProps extends TypographyProps {
  regular?: boolean;
  noMargin?: boolean;
}

const ValueModel = styled(Typography)<ValueProps>``;

export const Value = styled(ValueModel).withConfig({
  shouldForwardProp: (prop: any) => !['regular', 'noMargin'].includes(prop),
})`
  font-size: ${props => (props?.regular ? 14 : 12)}px;
  font-weight: ${props => (props?.regular ? 400 : 600)};
  margin-bottom: 16px;

  ${props =>
    props?.regular &&
    css`
      margin-top: -4px;
    `}

  ${props =>
    props?.noMargin &&
    css`
      margin-bottom: 0;
    `}
`;
