import { useAuth } from 'contexts/auth';
import { validateBr } from 'js-brasil';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import api from 'services/api';

function useContatoDialog({
  carteiraIds = [],
  carteiras = [],
  initialCateriaId,
  onClose = () => {},
  open,
}: any) {
  const { user }: any = useAuth();
  const [contatos, setContatos] = useState<any>([]);
  const [error, setError] = useState<any>({
    telefone: {
      error: false,
      message: null,
    },
  });
  const initialCarteira = contatos.find(
    (item: any) => item.carteiraId === initialCateriaId,
  );
  const [carteiraSelecionada, setCarteiraSelecionada] = useState(
    initialCarteira || {},
  );

  const [telefone, setTelefone] = useState('');

  const contatosFiltrados = useMemo(
    () => contatos.filter((item: any) => carteiraIds.includes(item.carteiraId)),
    [carteiraIds, contatos],
  );

  useEffect(() => {
    if (open) return;
    setTelefone('');
  }, [open]);

  useEffect(() => {
    if (contatosFiltrados.length === 1) {
      const [unicoContato] = contatosFiltrados;
      setCarteiraSelecionada(unicoContato);
    }

    if (!contatosFiltrados.length) {
      setCarteiraSelecionada({});
    }
  }, [contatosFiltrados]);

  useEffect(() => {
    if(carteiraIds.length && carteiras.length) {
      async function buscaContato() {
        const { data } = await api.post('busca-contato', {
          carteiraCrms: carteiras,
        });
  
        setContatos(data);
      }
  
      if (carteiras.length) {
        buscaContato();
      }
    }
  }, [carteiras]);

  useEffect(() => {
    if (Number(telefone?.length) === 14) telefoneValido();

    // eslint-disable-next-line
  }, [telefone]);

  async function sendPhone({ tipoContatoId }: any) {
    if (!telefoneValido()) return;

    insereContatoSelecionado({ telefone, tipoContatoId });
  }

  async function insereContatoSelecionado(params = {}) {
    const { data } = await api.post('insere-contato-selecionado', {
      cnpJ_CPF: user.cnpJ_CPF,
      carteiraCrmId: carteiraSelecionada.carteiraCrmId,
      ...params,
    });

    if (data.sucesso) {
      onClose();
      toast.success(data.msgRetorno);
      return;
    }

    toast.error(data.msgRetorno);
  }

  function telefoneValido() {
    if (!validateBr.telefone(telefone)) {
      setError({
        telefone: {
          message: 'Telefone inválido!',
          error: true,
        },
      });

      return false;
    }

    setError({
      telefone: {
        message: '',
        error: false,
      },
    });

    return true;
  }

  return {
    user,
    contatos,
    telefone,
    sendPhone,
    setTelefone,
    carteiraSelecionada,
    setCarteiraSelecionada,
    insereContatoSelecionado,
    contatosFiltrados,
    error,
  };
}

export default useContatoDialog;
