export const PARCELA_EM_ABERTO = 'EmAberto';

export const TIPO_DESCONTO_PERCENTUAL = 'Percentual';
export const TIPO_DESCONTO_MOMETARIO = 'Monetario';

export const MENSAGEM_DADOS_INVALIDOS = 'Os dados enviados estão errados.';

export const validationFieldEnums = {
  VALOR_PARCELA: 1,
  VALOR_ENTRADA: 2,
  QUANTIDADE_PARCELAS: 3
}

export const MENSAGEM_ERRO_OPCOES_PAGAMENTO =
'Tivemos um problema ao carregar as opções de pagamento da dívida e estamos trabalhando para resolver. ' +
'|Entre em contato através dos canais abaixo para continuar com a negociação.'

export const tipoEnvioBoletoEnum = {
  EMAIL: 1,
  SMS: 2,
  WHATSAPP: 3
}