import { Box, Typography, Paper, Divider, Grid } from "@material-ui/core";
import { Label, LabelLimit } from 'components/DividaAcordoItem/styles';
import { ILimitesAjustados } from "interfaces/ResumoBoleto";
import React, { Fragment } from "react";
import { getMoney } from "utils/transform";

interface ILimitesAjustadosProps {
  limitesAjustados?: ILimitesAjustados[],
  isDesktop?: boolean
}
 
const LimitesAjustados = ({ limitesAjustados, isDesktop }: ILimitesAjustadosProps) => {

  return (
    <Box mt={5}>
      <Typography style={{ fontSize: 17 }} variant="h6">
        <strong>Ajuste de Limites</strong>
      </Typography>
        <Paper variant="outlined" style={{
          display: 'flex',
          flexDirection: 'column',
          borderLeft: '5px solid #7D1416'
        }}>
          <Box style={{padding: 15, fontSize: 12}}>
            Veja Como ficam seus limites após o reparcelamento
          </Box>
        {limitesAjustados?.map((item, index) => (
          <Fragment key={index}>
            <Divider />
            <Box style={{padding: 15}}>
              <Box>
                <Label>
                  Agência e conta: {item?.agenciaContaClienteContrato} | {item?.contaClienteContrato}
                </Label>
              </Box>
              {item.limitesAjustadosAgencia?.map((item, index) => (
                <Box mb={3} key={index}>
                  <Box style={{fontSize: 14}}>
                    <strong>
                      {item.tituloProdutoOperacionalContrato}
                    </strong>
                  </Box>
                  <Grid container style={{display: 'flex', alignContent:'space-between'}}>
                    <Grid item xs={6} sm={6}>
                      <LabelLimit>
                        Atual
                        <br/>
                        {getMoney(item?.valorLimiteTotal)}           
                      </LabelLimit>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <LabelLimit style={{display: 'flex', justifyContent: isDesktop ? 'flex-start' : 'flex-end'}}>
                        <strong>
                          Novo Limite
                          <br/>
                          {getMoney(item?.valorLimiteAjustado)}
                        </strong>
                      </LabelLimit>
                    </Grid>
                  </Grid>                 
                </Box>
              ))}
            </Box>
          </Fragment>
        ))}
      </Paper>
    </Box>
  );
}
 
export default LimitesAjustados;