import React from 'react';
import { Box, Card } from '@material-ui/core';
import styled, { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.min.css';

const GlobalStyle = createGlobalStyle`
  * {
    scroll-behavior: smooth;
  }

  html, body, #root {
    height: 100%;
    margin: 0;

  }

  html {
    word-break: normal;
  }

  #root {
    display: flex;
    flex-direction: column;
  }

  body {
    background-color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
  }

  .MuiFormControl-marginNormal {
    margin-top: 10px;
  }
`;

export const WhatsAppFloatButton = styled.a`
  position:fixed;
  width:60px;
  height:60px;
  bottom:40px;
  right:40px;
  background-color:#25d366;
  color:#FFF;
  border-radius:50px;
  text-align:center;
  font-size:30px;
  box-shadow: 1px 1px 2px #888;
  transition: opacity 0.7s ease-out;

  img {
    margin-top: 9px
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const Form = styled(Card)
  .attrs({
    elevation: 3,
  })
  .withConfig({
    shouldForwardProp: prop => !['maxWidth'].includes(prop),
  })`
  max-width: ${props => props.maxWidth || 360}px;
  width: 100%;
  margin: auto;

  ${props => props.theme.breakpoints.down('sm')} {
    // height: 100%;
    border-radius: 0;
  }

  ${props => props.theme.breakpoints.down('xs')} {
    max-width: none;
  }
`;

export function FormContainer({ children, maxWidth = 360 }) {
  return (
    <Form maxWidth={maxWidth}>
      <Box width="100%" p={3}>
        {children}
      </Box>
    </Form>
  );
}

export default GlobalStyle;
