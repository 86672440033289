import React, { useState } from 'react';
import BackButton from 'components/BackButton';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Hidden,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import DocumentIcon from 'images/document-gray.svg';
import { Status, Label, Value } from 'components/DividaAcordoItem/styles';
import { When } from 'react-if';
import { formatToPercent, getFormattedDate, getMoney } from 'utils/transform';
import { FirmarAcordoButton } from './styles';
import { IDivida } from 'interfaces/Divida';
import { IOpcaoPagamento } from 'interfaces/BuscaOpcaoPagamento';
import { IResumoBoleto } from 'interfaces/ResumoBoleto';
import { ICustomizacao } from 'interfaces/Customizacao';
import InformacaoAdicionalDialog from 'dialogs/InformacaoAdicionalDialog/InformacaoAdicionalDialog';

interface IItauCredResumoBoletoProps {
  history: any;
  custom:ICustomizacao;
  isDesktop: boolean;
  resumoBoleto: IResumoBoleto;
  divida: IDivida;
  parcelamento: IOpcaoPagamento;
  bank?: any;
  onEmitirBoleto: any;
  loadingEmitirBoleto: boolean;
}

const ItauCredResumoBoleto = ({
  history,
  custom,
  isDesktop,
  resumoBoleto,
  divida,
  parcelamento,
  bank,
  onEmitirBoleto,
  loadingEmitirBoleto,
}: IItauCredResumoBoletoProps) => {
  const [informacaoAdicionalDialogOpen, setInformacaoAdicionalDialogOpen] = useState(false);

  function mostrarValorDesconto() {
    return (divida?.exibirDescontoMonetario && !!parcelamento.descontoMonetario) 
    || (divida?.exibirDescontoPercentual && !!parcelamento.descontoPercentual);
  }

  return (
    <div>
      <BackButton
        onClick={() => {
          history.goBack();
        }}
      >
        Voltar para a home
      </BackButton>
      <Box mt={2} mb={2}>
        <Typography
          variant="h5"
          style={{
            fontWeight: 500,
          }}
        >
          Revise as informações abaixo e confirme o acordo com a melhor
          negociação
        </Typography>
      </Box>

      <Box>
        <Paper>
          <Box width="100%" px={4} py={2}>
            <Box display="flex" alignItems="center">
              <Box mr={2}>
                <Status color="primary">
                  <strong>Dívida ativa</strong>
                </Status>
              </Box>
              <Box mr={1}>
                <img
                  style={{ marginBottom: 2 }}
                  src={DocumentIcon}
                  alt="Documento"
                />
              </Box>
              <Status>
                <span>
                  {Number(resumoBoleto?.contratos?.length)} Contrato
                  {Number(resumoBoleto?.contratos?.length) > 1 && 's'}
                </span>
              </Status>
            </Box>
            <Box>
              <Typography style={{ fontSize: 28 }} variant="h6">
                <strong>{bank.financeira}</strong>
              </Typography>
            </Box>

            <Box mt={3}>
              <Grid container alignItems="center">
                <Grid item xs={6} sm={3}>
                  <Box>
                    <Label>Data da negociação:</Label>
                    <Value regular>
                      {getFormattedDate(new Date().toISOString())}
                    </Value>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                  {!!resumoBoleto?.valorTotalComDesconto &&
                    resumoBoleto.valorTotalComDesconto !== 0 && (
                      <Box>
                        <Label>Valor do acordo</Label>
                        <Value regular>
                          {getMoney(resumoBoleto.valorTotalComDesconto)}
                        </Value>
                      </Box>
                    )}
                </Grid>
              </Grid>
            </Box>
            {resumoBoleto?.contratos?.map((contrato, index) => (
              <Box key={contrato.contrato} width="100%" mt={3}>
                <Grid container alignItems="center">
                  <Grid item xs={6} sm={3}>
                  {!!contrato?.produto && (
                    <Box>
                      <Label>Produto:</Label>
                      <Value>{contrato.produto}</Value>
                    </Box>
                  )}
                  </Grid>
                  <Grid item xs={6} sm>
                  {!!contrato?.contrato && (
                    <Box>
                      <Label>Contrato:</Label>
                      <Value>{contrato.contrato}</Value>
                    </Box>
                  )}
                  </Grid>
                  <Hidden smDown>
                    <Grid item>
                      {!!contrato?.valorTotalPagarComDesconto &&
                        contrato.valorTotalPagarComDesconto !== 0 && (
                          <>
                            <Label>Valor do contrato com desconto:</Label>
                            <Value
                              style={{
                                fontSize: 18,
                                textAlign: isDesktop ? 'right' : 'left',
                              }}
                            >
                              {getMoney(contrato.valorTotalPagarComDesconto)}
                            </Value>{' '}
                          </>
                        )}
                    </Grid>
                  </Hidden>
                </Grid>
                <Grid container>
                  <Grid item xs={6} sm={3} />
                  <When condition={!contrato?.dataContratacao}>
                    <Grid item xs={6} sm={3} />
                  </When>
                  <Hidden smUp>
                    <Grid item xs={6} />
                    <Grid item xs={6}>
                      {!!contrato.valorTotalPagarComDesconto &&
                        contrato.valorTotalPagarComDesconto !== 0 && (
                          <>
                            {' '}
                            <Label>
                              Valor do contrato na data do vencimento:
                            </Label>
                            <Value
                              style={{
                                fontSize: 18,
                                textAlign: isDesktop ? 'right' : 'left',
                              }}
                            >
                              {getMoney(contrato.valorTotalPagarComDesconto)}
                            </Value>
                          </>
                        )}
                    </Grid>
                  </Hidden>
                </Grid>
                {index + 1 !== Number(resumoBoleto?.contratos?.length) && <Divider />}
              </Box>
            ))}

            <Divider />
            
            <When condition={!!resumoBoleto.valorEntrada && !!resumoBoleto.dataVencimentoEntrada}>
              <Box>
                <Box width="100%" px={0} py={3}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {!!resumoBoleto?.valorEntrada && (
                      <Grid item xs={12} sm={4}>
                        <Typography style={{ fontSize: 17 }}>Entrada</Typography>
                      </Grid>
                    )}
                    {!!resumoBoleto?.dataVencimentoEntrada && (
                      <Grid item xs={12} sm={4}>
                        <>
                          {' '}
                          <Label>Data de vencimento da entrada</Label>
                          <Typography>
                            {getFormattedDate(resumoBoleto.dataVencimentoEntrada)}
                          </Typography>
                        </>
                      </Grid>
                    )}
                    {!!resumoBoleto?.valorEntrada && (
                      <Grid item xs={12} sm={4}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems={isDesktop ? 'flex-end' : 'flex-start'}
                        >
                          <Label>Valor da entrada</Label>
                          <Typography>
                            {getMoney(resumoBoleto.valorEntrada)}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                  </Box>
                </Box>
              </Box>
            </When>
            
            {!!resumoBoleto.valorEntrada && <Divider />}
            <Box mt={3}>
              <Box mb={1}>
                <Typography variant="caption" style={{ color: grey[400] }}>
                  Pagamento
                </Typography>
              </Box>
              <Paper variant="outlined">
                <Box p={4}>
                  <Grid container spacing={2}>
                    <Grid item container xs={12} alignItems="baseline" spacing={4}>
                      <Grid item xs={6} sm={6}>
                        <Typography
                          style={{ fontWeight: 'normal', fontSize: 17 }}
                        >
                          Forma de Pagamento
                        </Typography>
                        <Typography style={{ fontWeight: 'normal', fontSize: 15 }}>
                          {resumoBoleto.formaPagamentoDemaisParcelas}
                        </Typography>
                      </Grid>
                      <Grid item container xs={6} sm={6} justifyContent={isDesktop ? 'flex-end' : 'flex-start'}>
                      {!!mostrarValorDesconto() &&(
                          <Label
                            style={{
                              fontSize: 15,
                              fontWeight: 'bold'
                            }}
                          >
                            {divida?.exibirDescontoPercentual && parcelamento.descontoPercentual && (
                              <>({formatToPercent(parcelamento.descontoPercentual)})</>
                            )}
                            {divida?.exibirDescontoMonetario && parcelamento.descontoMonetario && (
                              <> {getMoney(parcelamento.descontoMonetario)}</>
                            )} de desconto
                          </Label>
                        )}                          
                        
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <Typography style={{ fontSize: 17 }}>
                          {Number(parcelamento?.parcelas?.length) > 1
                            ? `Entrada + ${Number(parcelamento?.parcelas?.length) - 1} parcelas`
                            : 'À vista'}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} alignItems = 'flex-end'>
                        {!!resumoBoleto?.dataVencimentoPrimeiraParcela && (
                          
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="flex-end"
                            >
                              <Label>Vencimento</Label>
                              <Typography>
                                {getFormattedDate(
                                  resumoBoleto.dataVencimentoPrimeiraParcela,
                                )}
                              </Typography>
                            </Box>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        {Number(parcelamento?.parcelas?.length) === 1 &&(
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems={isDesktop ? 'flex-start' : 'flex-start'}
                          >
                            <Value
                              style={{
                                fontSize: 18,
                              }}
                            >
                              {getMoney(resumoBoleto.valorTotalComDesconto)}
                            </Value>
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        {!!resumoBoleto?.valorParcelaComDesconto && Number(parcelamento?.parcelas?.length) > 1 &&(
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems={isDesktop ? 'flex-start' : 'flex-start'}
                          >
                            <Label>Valor da entrada</Label>
                            <Typography>
                              {Number(resumoBoleto.valorEntrada) > 1
                              ? getMoney(resumoBoleto.valorEntrada)
                              : getMoney(resumoBoleto.valorParcelaComDesconto)}
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        {!!resumoBoleto?.valorParcelaComDesconto && Number(parcelamento?.parcelas?.length) > 1 &&(
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems={isDesktop ? 'flex-start' : 'flex-start'}
                          >
                            <Label>Valor da parcela</Label>
                            <Typography>
                              {getMoney(resumoBoleto.valorParcelaComDesconto)}
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>
            <When condition={Number(parcelamento?.parcelas?.length) > 1}>
              <Box mt={3}>
                <Grid container>
                  <Grid item container xs={12} sm={10}>
                    <Grid item xs={6} sm={4}>
                      <Label>CET ao mês</Label>
                      <Value>{resumoBoleto.taxaJurosMensalCet}%</Value>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Label>CET ao ano</Label>
                      <Value>{resumoBoleto.taxaJurosAnualCet}%</Value>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Label>IOF</Label>
                      <Value>
                        {resumoBoleto.descricaoIof ||
                          `${resumoBoleto.valorIofPercentual}%`}
                      </Value>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Label>Taxa de juros ao mês</Label>
                      <Value>{resumoBoleto.taxaJurosMensalResumo}%</Value>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Label>Taxa de juros ao ano</Label>
                      <Value>{resumoBoleto.taxaJurosAnualResumo}%</Value>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </When>
            
            <When condition={!!custom?.InformacaoAdicionalDialog}>
              <Box mt={1}>
                <Value>
                  Para visualizar mais detalhes sobre essa contração,
                  {' '}
                  <span 
                    style={{textDecoration: "underline", color: '#8DB6E4', cursor: 'pointer'}} 
                    onClick={() => setInformacaoAdicionalDialogOpen(true)}
                  >
                    clique aqui
                  </span>
                  .
                </Value>
                <Value>
                  Ao confirmar este acordo, declaro que <strong>li e concordo</strong> com as condições informadas acima.
                </Value>
              </Box>
            </When>

            <Box mt={3}>
              <FirmarAcordoButton
                onClick={() => onEmitirBoleto()}
                color="primary"
                variant="contained"
              >
                {!loadingEmitirBoleto ? (
                    resumoBoleto?.indicadorFormalizacao 
                    ? 'Confirmar Formalização'
                    : 'Confirmar Acordo'
                ) : (
                  <CircularProgress size={20} style={{ color: 'white' }} />
                )}
              </FirmarAcordoButton>
            </Box>

            <When condition={Number(parcelamento?.parcelas?.length) > 1}>
              <Box mt={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={11}>
                    <Label>
                      <strong>IOF:</strong> Imposto Operacional Financeiro
                    </Label>
                  </Grid>
                  <Grid item xs={12} sm={11}>
                    <Label>
                      <strong>CET:</strong> Custo Efetivo Total
                    </Label>
                  </Grid>
                </Grid>
              </Box>
            </When>
          </Box>
        </Paper>
      </Box>
      <InformacaoAdicionalDialog
        open={informacaoAdicionalDialogOpen}
        onClose={() => setInformacaoAdicionalDialogOpen(false)}
        custom={custom}
      />
    </div>
  );
};

export default ItauCredResumoBoleto;
