import React, { lazy } from 'react';

// Icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

// Pages
import Login from 'pages/Login';
import ForgotPassword from 'pages/ForgotPassword';
import Sucesso from 'pages/Sucesso/Sucesso';
import DividaDetalhe from 'pages/DividaDetalhe';
import EnviarAcordo from 'pages/EnviarAcordo';
import ResumoBoleto from 'pages/ResumoBoleto';
import BradescoReorgDividaDetalhe from 'pages/DividaDetalhe/BradescoReorgDividaDetalhe';

// Logged Pages
const Dashboard = lazy(() => import('pages/Dashboard/Dashboard'));

const config = {
  public: [
    {
      path: '/login',
      component: Login,
      exact: true,
    },
    {
      path: '/esqueci-minha-senha',
      component: ForgotPassword,
      exact: true,
    },
    {
      path: '/sucesso',
      component: Sucesso,
      title: 'Sucesso',
      exact: true,
      icon: <AccountBoxIcon />,
    },
  ],
  protected: [
    {
      path: '/',
      component: Dashboard,
      title: 'Dashboard',
      exact: true,
      icon: <DashboardIcon />,
    },
    {
      path: '/divida/detalhes',
      component: DividaDetalhe,
      title: 'Detalhe',
      exact: true,
      icon: <AccountBoxIcon />,
    },
    {
      path: '/enviar-acordo',
      component: EnviarAcordo,
      title: 'Enviar Acordo',
      exact: true,
    },
    {
      path: '/resumo-boleto',
      component: ResumoBoleto,
      title: 'Resumo Boleto',
      exact: true,
    }
  ],
};

export default config;
