import { Box, Typography, IconButton, useTheme} from "@material-ui/core";
import styled from 'styled-components';


export const InfoContainer = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 20px;
  margin: 0 40px 0;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0px;
`;

export const InfoContent = styled(Typography)`
  font-size: 14px;
  margin-bottom: 8px;
  width: 100%;
`;

export const InfoTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.palette.darkOrange};
  margin-top: 20px;
  margin-bottom: 14px;
`;

export const ExternalLinks = styled.a`
  color: #000000DE;
`;