import React from 'react';
import DialogForm from 'components/DialogForm';
import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { When } from 'react-if';
import { tiposContato } from './tiposContato';
import useContatoDialog from './hook';
import { CustomMessageLabel } from './styles';

function ContatoDialog({
  open,
  onClose,
  carteiraIds = [],
  carteiras = [],
  initialCateriaId,
  customMessage
}: any) {
  const {
    telefone,
    setTelefone,
    sendPhone,
    carteiraSelecionada,
    setCarteiraSelecionada,
    contatosFiltrados,
    error,
    insereContatoSelecionado,
  }: any = useContatoDialog({
    carteiraIds,
    carteiras,
    initialCateriaId,
    onClose,
    open,
  });

  return (
    <div>
      <DialogForm
        id="contact_form"
        maxWidth="xs"
        title={!!customMessage ? 'Negociação Indisponível!' : 'Entre em contato!'}
        open={open}
        onClose={onClose}
      >
        {() => (
          <>
            <When condition={contatosFiltrados.length > 1}>
              <Box py={2}>
                <Autocomplete
                  id="combo-box-demo"
                  options={contatosFiltrados}
                  onChange={(_, carteira) => {
                    setCarteiraSelecionada(carteira);
                  }}
                  getOptionLabel={(option: any) => option.carteiraCrmText}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Selecione a Carteira"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Box>
            </When>
            
            {
              !!customMessage && (
                <Box>
                  {customMessage.split('|').map((message: string) => (
                    <CustomMessageLabel>
                      {message}
                    </CustomMessageLabel>
                  ))}
                </Box>
            )}

            {carteiraSelecionada?.contatos?.map((contato: any) =>
              tiposContato[contato?.tipoContatoId].render({
                contato,
                setTelefone,
                sendPhone,
                telefone,
                error,
                insereContatoSelecionado,
              }),
            )}
          </>
        )}
      </DialogForm>
    </div>
  );
}

export default ContatoDialog;
