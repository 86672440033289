import React from "react";
import { Box, Grid, Hidden, Divider, Typography, Paper } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { Label, Value } from "components/DividaAcordoItem/styles";
import LimitesAjustados from "components/LimitesAjustados/LimitesAjustados";
import { IOpcaoPagamento } from "interfaces/BuscaOpcaoPagamento";
import { IResumoBoleto } from "interfaces/ResumoBoleto";
import { When } from "react-if";
import { getFormattedDate, getMoney } from "utils/transform";

interface IResumoBoletoDetalhesUsingAPIProps {
  resumoBoleto: IResumoBoleto;
  isDesktop: boolean;
  parcelamento: IOpcaoPagamento;
}

const ResumoBoletoDetalhesUsingAPI = ({ resumoBoleto, isDesktop, parcelamento }: IResumoBoletoDetalhesUsingAPIProps) => {
  return (
    <>
      <Box mt={3}>
        <Grid container alignItems="center">
          <Grid item xs={6} sm={3}>
            <Box>
              <Label>Data da negociação:</Label>
              <Value regular>
                {getFormattedDate(new Date().toISOString())}
              </Value>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            {!!resumoBoleto?.valorTotal &&
              resumoBoleto.valorTotal !== 0 && (
                <Box
                  display='flex'
                  flexDirection='column'
                  alignItems={isDesktop ? "flex-start" : "flex-end"}>
                  <Label>Valor do acordo</Label>
                  <Value regular>
                    {getMoney(resumoBoleto.valorTotal)}
                  </Value>
                </Box>
              )}
          </Grid>
        </Grid>
      </Box>

      {resumoBoleto?.contratos?.map((contrato, index) => (
        <Box key={contrato.contrato} width="100%" mt={1}>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={12}>
              {!!contrato?.produto && (
                <Box>
                  <Label>Produto:</Label>
                  <Value>{contrato.produto}</Value>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={12}>
              {!!contrato?.contrato && (
                <Box>
                  <Label>Contrato:</Label>
                  <Value>{contrato.contrato}</Value>
                </Box>
              )}
            </Grid>
            <Hidden smDown>
              <Grid item>
                {!!contrato?.valorTotalPagarComDesconto &&
                  contrato.valorTotalPagarComDesconto !== 0 && (
                    <>
                      <Label>Valor do contrato:</Label>
                      <Value
                        style={{
                          fontSize: 18,
                          textAlign: isDesktop ? 'right' : 'left',
                        }}
                      >
                        {getMoney(contrato.valorTotalPagarComDesconto)}
                      </Value>{' '}
                    </>
                  )}
              </Grid>
            </Hidden>
          </Grid>
          <Grid container>
            <Grid item xs={6} sm={3} />
            <When condition={!!contrato?.dataContratacao}>
              <Grid item xs={6} sm={3} />
            </When>
            <Hidden smUp>
              <Grid item xs={6} />
              <Grid item xs={6}>
                {!!contrato.valorTotalPagarComDesconto &&
                  contrato.valorTotalPagarComDesconto !== 0 && (
                    <>
                      {' '}
                      <Label>
                        Valor do contrato na data do vencimento:
                      </Label>
                      <Value
                        style={{
                          fontSize: 18,
                          textAlign: isDesktop ? 'right' : 'left',
                        }}
                      >
                        {getMoney(contrato.valorTotalPagarComDesconto)}
                      </Value>
                    </>
                  )}
              </Grid>
            </Hidden>
          </Grid>
          {index + 1 !== Number(resumoBoleto?.contratos?.length) && <Divider />}
        </Box>
      ))}

      <Divider />

      <Box mt={3}>
        <Box mb={1}>
          <Typography variant="caption" style={{ color: grey[400] }}>
            Pagamento
          </Typography>
        </Box>
        <Paper variant="outlined">
          <Box p={4}>
            <Grid container spacing={2}>
              <Grid item container xs={12} alignItems="baseline" spacing={4}>
                <Grid item xs={12} sm={6}>
                  <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      style={{ fontWeight: 'normal', fontSize: 17 }}
                    >
                      Forma de Pagamento
                    </Typography>
                    <Typography style={{ fontWeight: 'normal', fontSize: 15 }}>
                      {resumoBoleto.formaPagamentoDemaisParcelas}
                    </Typography>
                    <Typography style={{ fontSize: 14 }}>
                      {Number(parcelamento?.parcelas?.length) > 1
                        ? `${Number(parcelamento?.parcelas?.length)} parcelas`
                        : 'À vista'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6}>
                  {!!resumoBoleto?.valorParcela && Number(parcelamento?.parcelas?.length) > 1 && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems={isDesktop ? 'flex-start' : 'flex-start'}
                    >
                      <Label>Valor das parcelas</Label>
                      <Typography>
                        {getMoney(resumoBoleto.valorParcela)}
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={6} sm={6}>
                  {!!resumoBoleto?.dataVencimentoPrimeiraParcela && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      <Label>Vencimento da primeira parcela</Label>
                      <Typography>
                        {getFormattedDate(
                          resumoBoleto.dataVencimentoPrimeiraParcela,
                        )}
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>

      <When condition={Number(parcelamento?.parcelas?.length) > 1}>
        <Box mt={3} mb={3}>
          <Grid container>
            <Grid item container xs={12} sm={10}>
              <Grid item xs={6} sm={4}>
                <Label>CET* ao mês</Label>
                <Value>{resumoBoleto.taxaJurosMensalCet}%</Value>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Label>CET* ao ano</Label>
                <Value>{resumoBoleto.taxaJurosAnualCet}%</Value>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Label>IOF*</Label>
                <Value>
                  {resumoBoleto.descricaoIof ||
                    `${resumoBoleto.valorIofPercentual}%`}
                </Value>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Label>Taxa de juros ao mês</Label>
                <Value>{resumoBoleto.taxaJurosMensalResumo}%</Value>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Label>Taxa de juros ao ano</Label>
                <Value>{resumoBoleto.taxaJurosAnualResumo}%</Value>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Label>TAC</Label>
                <Value>{getMoney(resumoBoleto.valorTac)} - {resumoBoleto.percentualTac}</Value>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Label>Valor do seguro</Label>
                <Value>{getMoney(resumoBoleto.valorSeguro)}</Value>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </When>

      <Divider />

      <When condition={Number(resumoBoleto.limitesAjustados?.length) > 0}>
        <LimitesAjustados
          isDesktop={isDesktop}
          limitesAjustados={resumoBoleto?.limitesAjustados}
        />
      </When>
    </>
  );
}

export default ResumoBoletoDetalhesUsingAPI;