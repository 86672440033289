export const normalizeText = (text : string) =>{
    return text
    .replace(/[áäâàã]/g, 'a')
    .replace(/[éëêè]/g, 'e')
    .replace(/[íïîì]/g, 'i')
    .replace(/[óöôòõ]/g, 'o')
    .replace(/[úüûù]/g, 'u')
    .replace(/[ç]/g, 'c')
    .replace(/\s/g, '')
    .toLocaleLowerCase();
}