import { Box } from "@material-ui/core";
import styled from 'styled-components';

export const ContainerBottom = styled(Box)`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
  padding: 10px;
  margin: 0;
`;