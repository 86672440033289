import React from 'react';
import { Button, Box, Dialog } from '@material-ui/core';
import { InfoContent, InfoContainer, CloseButton, InfoTitle, ExternalLinks } from './style';
import CloseIcon from '@material-ui/icons/Close';
import CredorNotFound from 'images/credor-not-found.svg';

interface ICredorNotFoundDialogProps {
  open: boolean;
  onClose: () => void;
  financeira?: string;
}

const CredorNotFoundDialog = ({ open, onClose, financeira }: ICredorNotFoundDialogProps) => {
  return (
    <Dialog 
      open={open}
      maxWidth={'xs'}
      onClose={onClose}
    >
      <CloseButton onClick={onClose}> 
        <CloseIcon color="primary" />
      </CloseButton>
      <InfoContainer>
        <Box>
          <img src={CredorNotFound} alt="Cpf/Cnpj não encontrado" />
        </Box>
        <InfoTitle>
          Desculpe, não localizamos 
          <br/>o seu CPF/CNPJ. 
        </InfoTitle>
        {financeira === 'bradesco' && (
          <>
            <InfoContent>
              Por favor, acesse os canais digitais do Bradesco: Fone Fácil, Internet Banking, Autoatendimento e App Bradesco. 
            </InfoContent>
            <InfoContent>
              <b>Portal Bradesco:</b> 
              <br/>
              <ExternalLinks href='https://bradesco.negociedigital.com.br' target='_blank'>
                https://bradesco.negociedigital.com.br
              </ExternalLinks>
              <br/>
              <b>Portal Bradesco Cartões:</b>
              <br/>
              <ExternalLinks href='https://bradescard.rcbserv.com.br/Home' target='_blank'>
                https://bradescard.rcbserv.com.br/Home
              </ExternalLinks>
            </InfoContent>
            <InfoContent>
              Se não tiver acesso, procure seu gerente ou Agência Bradesco mais próxima.
            </InfoContent>
          </>
        )}
        <Button
          size="large"
          style={{ height: 45, width: 150}}
          variant="contained"
          color="primary"
          onClick={onClose}
        >
          Entendi
        </Button>
      </InfoContainer>
    </Dialog>
  );
}

export default CredorNotFoundDialog;