import React from "react";
import { Box, Grid, Divider, Typography, Paper } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { Label, Value } from "components/DividaAcordoItem/styles";
import { IOpcaoPagamento } from "interfaces/BuscaOpcaoPagamento";
import { ICustomizacao } from "interfaces/Customizacao";
import { IDivida } from "interfaces/Divida";
import { getFormattedDate, getMoney } from "utils/transform";

interface IResumoBoletoDetalhesProps {
  divida: IDivida;
  parcelamento: IOpcaoPagamento;
  isDesktop: boolean;
  custom: ICustomizacao;
}

const ResumoBoletoDetalhes = ({ parcelamento, divida, isDesktop, custom }: IResumoBoletoDetalhesProps) => {
  const mostrarValorDesconto = !divida?.exibirDescontoMonetario && !divida?.exibirDescontoPercentual;

  return (
    <>
      <Box mt={3}>
        <Grid container alignItems="center">
          <Grid item xs={6} sm={4}>
            <Box>
              <Label>Data da negociação:</Label>
              <Value regular>
                {getFormattedDate(new Date().toISOString())}
              </Value>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}
            style={{
              display: "flex",
              justifyContent: isDesktop ? "flex-start" : "flex-end"
            }}
          >
            {!!parcelamento?.valor && parcelamento?.valor !== 0 && (
              <Box>
                <Label>Valor do acordo</Label>
                <Value regular>{getMoney(parcelamento.valor)}</Value>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>

      {divida?.contratos?.map((contrato, index) => (
        <Box key={contrato.documento} width="100%" mt={1}>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={12}>
              {!!contrato?.produto && (
                <Box>
                  <Label>Produto:</Label>
                  <Value>{contrato.produto}</Value>
                </Box>
              )}
            </Grid>
            <Grid item xs={6} sm={4}>
              {!!contrato?.documento && (
                <Box>
                  <Label>Contrato:</Label>
                  <Value>{contrato.documento}</Value>
                </Box>
              )}
            </Grid>
            <Grid item xs={6} sm={3}
              style={{
                display: "flex",
                alignItems: isDesktop ? "flex-start" : "flex-end",
                flexDirection: "column"
              }}
            >
              {!!contrato?.valor && contrato?.valor !== 0 && (
                <>
                  {' '}
                  <Label
                    style={{
                      fontSize: 13,
                      textAlign: isDesktop ? 'right' : 'left',
                    }}
                  >
                    {custom && (
                      custom.ResumoBoleto
                    )}
                  </Label>
                  <Value
                    style={{
                      fontSize: 13,
                    }}
                  >
                    {getMoney(contrato.valor)}
                  </Value>{' '}
                </>
              )}
            </Grid>
          </Grid>
          {index + 1 !== Number(divida?.contratos?.length) && <Divider />}
        </Box>
      ))}

      <Box mt={3}>
        <Box mb={1}>
          <Typography variant="caption" style={{ color: grey[400] }}>
            Pagamento
          </Typography>
        </Box>
        <Paper variant="outlined">
          <Box p={isDesktop ? 4 : 2}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    Pagamento
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 'normal', fontSize: 14 }}
                  >
                    {Number(parcelamento?.parcelas?.length) > 1
                      ? `Entrada + ${(Number(parcelamento.parcelas?.length) - 1)} parcelas`
                      : 'À vista'}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} justifyContent={isDesktop ? 'flex-end' : 'flex-start'}>
                  {!!divida?.tipoDesconto && !!mostrarValorDesconto && (
                    <Label
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        textAlign: 'right',
                      }}
                    >
                      {divida?.tipoDesconto === 'Percentual' && (
                        <>{parcelamento.desconto}% de desconto</>
                      )}
                      {divida?.tipoDesconto === 'Monetario' && (
                        <>R$ {parcelamento.desconto} de desconto</>
                      )}
                    </Label>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} style={{padding: "0 8px"}}>
                  <Divider />
                </Grid>
                <Grid item xs={6} sm={6} alignItems="flex-end">
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems={'flex-start'}
                  >
                    <Label>{!!parcelamento?.parcelas && parcelamento?.parcelas.length > 1 ? "Valor da entrada" : "Valor a pagar"}</Label>
                    <Typography style={{ fontSize: 13 }}>
                      {getMoney(parcelamento.valorEntrada)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6}>
                  {!!parcelamento?.parcelas && !!parcelamento?.parcelas[0]?.vencimento && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-end"
                    >
                      <Label>Vencimento</Label>
                      <Typography style={{ fontSize: 13 }}>
                        {getFormattedDate(
                          parcelamento.parcelas[0].vencimento,
                        )}
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={6} sm={6}>
                  {!!parcelamento?.parcelas && parcelamento?.parcelas.length > 1 && !!parcelamento?.valorParcela &&
                    parcelamento?.valorParcela !== 0 && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Label
                          style={{
                            fontSize: 13,
                            textAlign: 'left',
                          }}
                        >
                          Valor da parcela
                        </Label>
                        <Typography
                          style={{
                            fontSize: 13,
                            textAlign: isDesktop ? 'right' : 'left',
                          }}
                        >
                          {getMoney(parcelamento.valorParcela)}
                        </Typography>
                      </Box>
                    )}
                </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </>
  );
}

export default ResumoBoletoDetalhes;