import React, { useCallback, useState, useEffect, useMemo } from 'react';

import { useMediaQuery, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'contexts/auth';
import api from 'services/api';
import Centered from 'components/Centered';
import { toast } from 'react-toastify';
import { encryptData, decryptData } from 'utils/crypto';
import ItauCredResumoBoleto from './ItauCredResumoBoleto';
import GenericResumoBoleto from './GenericResumoBoleto';
import BradescoResumoBoleto from './BradescoResumoBoleto';
import BradescoResumoBoleto2 from './BradescoResumoBoleto2';
import { IDivida } from 'interfaces/Divida';
import {
  IOpcaoPagamento,
  IBuscaOpcaoPagamento,
} from 'interfaces/BuscaOpcaoPagamento';
import { IResumoBoleto } from 'interfaces/ResumoBoleto';
import { ICustomizacao } from 'interfaces/Customizacao';
import { buscaCustomService } from 'services/buscaCustomizacao/buscaCustomizacao';
import { logEvent } from 'config/googleAnalytics';
import ContatoDialog from 'dialogs/ContatoDialog';

const ResumoBoleto: React.FC = () => {
  const [custom, setCustom] = useState<ICustomizacao>({});
  const { user, bank, dividasAuthContext } = useAuth();
  const [openContatoDialog, setOpenContatoDialog] = useState(false);
  const isMobile = useMediaQuery((th: any) => th?.breakpoints?.down('sm'));
  const history = useHistory();
  const isDesktop = useMediaQuery((theme: any) => theme?.breakpoints?.up('sm'));
  const [resumoBoleto, setResumoBoleto] = useState<IResumoBoleto>(
    decryptData(localStorage.getItem('@resumoBoletoDivida') || '{}'),
  );
  const [divida, setDivida] = useState<IDivida>(
    decryptData(localStorage.getItem('@dividaSucesso') || '{}'),
  );
  const [opcao] = useState<IBuscaOpcaoPagamento>(
    decryptData(localStorage.getItem('@opcao') || '{}'),
  );
  const [parcelamento, setParcelamento] = useState<IOpcaoPagamento>(
    decryptData(localStorage.getItem('@resumoBoletoParcelamento') || '{}'),
  );
  const [dataValidade, setDataValidade] = useState(
    decryptData(localStorage.getItem('@dataValidade') || '{}'),
  );
  const [loading, setLoading] = useState(true);
  const [loadingEmitirBoleto, setLoadingEmitirBoleto] = useState(false);
  // const opcao = decryptData(localStorage.getItem('@opcao'));

  const carteiraIds = useMemo(
    () => [
      divida?.carteiraId,
      divida?.carteiraId,
    ],
    [divida],
  );

  const carteiras = useMemo(
    () => [
      {
        crmId: divida?.crm,
        carteiraId: divida?.carteiraId,
        fase: divida?.fase,
        ranking: divida?.ranking,
      },
      {
        crmId: divida?.crm,
        carteiraId: divida?.carteiraId,
        fase: divida?.fase,
        ranking: divida?.ranking,
      },
    ],
    [divida],
  );

  const usaResumoApi: boolean = useMemo(() => {
    return !!opcao?.chamarResumoBoleto;
  }, [opcao]);

  const getResumoBoleto = useCallback(async () => {
    const parcelamento: IOpcaoPagamento = decryptData(
      localStorage.getItem('@resumoBoletoParcelamento') || '{}',
    );

    const updateDivida = dividasAuthContext?.find(
      _ => _.documento === divida?.documento,
    );
    const dataValidade = decryptData(
      localStorage.getItem('@dataValidade') || '{}',
    );

    if (!parcelamento || !divida) {
      history.push('/');
      return;
    }

    setResumoBoleto(
      decryptData(localStorage.getItem('@resumoBoletoDivida') || '{}'),
    );
    setDivida(updateDivida as IDivida);
    setParcelamento(parcelamento);
    setDataValidade(dataValidade);
    setLoading(false);
  }, [history, dividasAuthContext, divida]);

  const onEmitirBoleto = useCallback(async () => {
    setLoadingEmitirBoleto(true);

    const dataVencimento = !usaResumoApi
      ? dataValidade
      : resumoBoleto.dataVencimentoEntrada !== null
      ? resumoBoleto.dataVencimentoEntrada
      : resumoBoleto.dataVencimentoPrimeiraParcela;

    const identificador = !usaResumoApi
      ? parcelamento?.codigo
      : resumoBoleto.identificador;

    const { data } = await api.post('emitir-boleto', {
      Crm: divida?.crm,
      Contrato: divida?.numero,
      Carteira: divida?.carteiraId,
      CNPJ_CPF: user?.cnpJ_CPF,
      Valor: parcelamento?.valor,
      Parcelas: Number(parcelamento.parcelas?.length),
      DataVencimento: dataVencimento,
      Identificador: identificador,
      OpcaoDebitoConta: parcelamento.opcaoDebitoConta,
      OpcaoChequeEspecial: parcelamento.opcaoChequeEspecial,
      TipoContrato: divida?.tipoContrato
    });

    setLoadingEmitirBoleto(false);

    if (!!data?.sucesso) {
      toast.success(data.msgRetorno);
      localStorage.setItem('@dividaSucesso', encryptData(divida));
      localStorage.setItem('@retornoEmitirBoleto', encryptData(data));
      localStorage.setItem('@resumoBoleto', encryptData(resumoBoleto));

      history.push('/divida/detalhes');
      logEvent('firmou acordo e emitiu boleto', divida?.carteiraId, divida?.crm, 
      user?.cnpJ_CPF, divida?.carteiraCrmText);
      return;
    } else {
      toast.error(data.msgRetorno);
      setOpenContatoDialog(true);
      return;
    }
  }, [
    usaResumoApi,
    resumoBoleto,
    divida,
    parcelamento,
    dataValidade,
    history,
    user,
  ]);

  useEffect(() => {
    async function buscaCustomizacao() {
      if(divida?.crm && divida?.carteiraId) {
        const { data: newCustom } = await buscaCustomService({
          crms: divida?.crm,
          carteiraId: divida?.carteiraId,
        })
        setCustom(newCustom);
      }
    }
    buscaCustomizacao();
  }, []);

  useEffect(() => {
    getResumoBoleto();
  }, [getResumoBoleto]);

  if (loading) {
    return (
      <Centered>
        <CircularProgress />
      </Centered>
    );
  }

  return(
    <>
      <ContatoDialog
        onClose={() => setOpenContatoDialog(false)}
        open={openContatoDialog}
        carteiraIds={carteiraIds}
        carteiras={carteiras}
        initialCateriaId={divida?.carteiraId}
        customMessage={custom.ResumoBoletoContatoDialog}
      />
      {usaResumoApi && bank?.financeira?.toLowerCase() === 'itaú'? (
        <ItauCredResumoBoleto
          history={history}
          custom={custom}
          isDesktop={isDesktop}
          resumoBoleto={resumoBoleto}
          divida={divida}
          parcelamento={parcelamento}
          bank={bank}
          onEmitirBoleto={onEmitirBoleto}
          loadingEmitirBoleto={loadingEmitirBoleto}
        />
      ) : bank?.financeira?.toLowerCase() === 'bradesco' ? (
        <BradescoResumoBoleto2
          history={history}
          custom={custom}
          isDesktop={isDesktop}
          resumoBoleto={resumoBoleto}
          divida={divida}
          parcelamento={parcelamento}
          bank={bank}
          onEmitirBoleto={onEmitirBoleto}
          loadingEmitirBoleto={loadingEmitirBoleto}
          isMobile={isMobile}
          usaResumoApi={usaResumoApi}
        />
      ) : (
        <GenericResumoBoleto
          history={history}
          custom={custom}
          isDesktop={isDesktop}
          isMobile={isMobile}
          divida={divida}
          parcelamento={parcelamento}
          bank={bank}
          onEmitirBoleto={onEmitirBoleto}
          loadingEmitirBoleto={loadingEmitirBoleto}
        />
      )}
    </>
  )
};

export default ResumoBoleto;
