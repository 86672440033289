import React, { createContext, useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import api, { bpDigitalApi } from 'services/api';
import { encryptData, decryptData } from 'utils/crypto';
import { IDivida } from 'interfaces/Divida';
import { IAcordo } from 'interfaces/Acordo';
import { ILoginPayload } from 'interfaces/GeneralInterfaces';
import { IAuthContext } from 'interfaces/GeneralInterfaces';
import { IUser, ICredores } from 'interfaces/User';
import { buscaCredoresService, buscaCredoresServiceWithName } from 'services/buscaCredores/buscaCredores';

// const AuthContext = createContext<IAuthContext | null>(null);
const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export function login(values: ILoginPayload) {
  return bpDigitalApi.post('', {
    AppId: process.env.REACT_APP_ID,
    Captcha: values.recaptcha,
    Usuario: values.documento,
    AppPass: process.env.REACT_APP_PASS,
  });
}

export const AuthProvider: React.FC<React.ReactNode> = ({ children }: any) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [bank, setBank] = useState<ICredores | null>(null);
  const [loading, setLoading] = useState(true);
  const [dividasAuthContext, setDividasAuthContext] = useState<
    IDivida[] | undefined
  >([]);
  const [acordosAuthContext, setAcordosAuthContext] = useState<
    IAcordo[] | undefined
  >([]);

  useEffect(() => {
    function setInterceptors() {
      api.interceptors.response.use(
        function (response) {
          return response;
        },
        function (error) {
          if (error?.response?.status === 401) {
            const signed = !!localStorage.getItem('@token');

            if (signed) {
              toast.error('Sua sessão expirou, logue novamente.');
              signOut();
            } else {
              if (error.response.config.url.includes('login')) {
                toast.error('Credenciais inválidas');
              }
            }
          }

          if (error?.response?.status !== 401) {
            const mensagem = error?.response?.data?.mensagemInsucesso;

            toast.error(
              mensagem ||
              'Desculpe, tivemos um erro! Mas não se preocupe, você pode acessar um dos canais de atendimento que já iremos te atender.',
            );
          }

          return Promise.reject(error);
        },
      );
    }

    function loadStoragedData(): void {
      const storagedUser = localStorage.getItem('@user');
      const storagedToken = localStorage.getItem('@token');
      const storagedBank = localStorage.getItem('@bank');

      if (!storagedUser || !storagedToken || !storagedBank) {
        signOut();
        setLoading(false);
        return;
      }

      if (storagedUser && storagedToken && storagedBank) {
        api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
        setUser(decryptData(storagedUser) as IUser | null);
        setBank(decryptData(storagedBank) as ICredores | null);
      }

      // Tracker
      window.my_global_var = decryptData(storagedUser)?.cnpJ_CPF || '';

      setLoading(false);
    }

    setInterceptors();
    loadStoragedData();
  }, []);

  const signIn = async (values: ILoginPayload): Promise<IUser | undefined> => {
    const { data } = await login(values);

    signOut();

    const { data: loggedUser } =
      values.nome !== '' && values.nome !== undefined ?
        await buscaCredoresServiceWithName({
          headers: { Authorization: `Bearer ${data.token}` },
        }, `${values.nome}`)
        :
        await buscaCredoresService({
          headers: { Authorization: `Bearer ${data.token}` },
        });


    if (!loggedUser?.mensagemRetorno?.sucesso) {
      const emptyUser: IUser = {
        cnpJ_CPF: undefined,
        credores: undefined,
        nome: undefined,
        mensagemRetorno: loggedUser.mensagemRetorno
      }
      return emptyUser;
    }

    if (!loggedUser?.credores?.length) return;

    api.defaults.headers.Authorization = `Bearer ${data.token}`;
    setUser(loggedUser);
    localStorage.setItem('@user', encryptData(loggedUser));
    localStorage.setItem('@token', data.token);

    window.my_global_var = loggedUser?.cnpJ_CPF || '';

    return loggedUser;
  };

  async function signOut() {
    localStorage.clear();
    setUser(null);
    setBank(null);
    setDividasAuthContext([]);
    setAcordosAuthContext([]);
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user && !!bank,
        user,
        bank,
        setBank,
        signIn,
        signOut,
        loading,
        dividasAuthContext,
        acordosAuthContext,
        setDividasAuthContext,
        setAcordosAuthContext,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

export function useAuth(): IAuthContext {
  const context = useContext(AuthContext);

  return context;
}
