import ReactGA from 'react-ga';

export const analyticsInit = () =>
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTIC_ID);

export const trackingPageView = () =>
  ReactGA.pageview(window.location.pathname);

export const logEvent = (method, carteira, crm, cpf, nomeCarteira, idAcordo) => {
  const actionLog = `${method} - codCarteira: ${carteira} | nomeCarteira: ${nomeCarteira} | crm: ${crm}
  ${idAcordo ? ` | idAcordo: ${idAcordo}` : ''}`;

  ReactGA.event({
    category: `Portal - ${process.env.REACT_APP_ENVIRONMENT}`,
    action: actionLog,
    label: `Cpf: ${cpf}`,
    value: 1
  });
}