import MUIButton from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';

export const LinkButton = styled(MUIButton)`
  text-align: left;
  text-transform: none;
  height: 60px;
  display: flex;
  justify-content: flex-start;
`;

export const SubmitButton = styled(MUIButton)`
  text-transform: none;
  font-weight: bold;
  font-size: 1.15rem;
`;

export const BoxInformacaoAdicional2 = styled(Box)`
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.8rem;
  line-height: 1rem;
`;

export const ContainerBottom = styled(Box)`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
  padding: 10px;
  margin: 0;
`;