import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router';

import {
  Box,
  Card,
  CardContent,
  Grid,
  Divider,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { decryptData } from 'utils/crypto';
import { Label, Value } from 'components/DividaItem/styles';
import { getFormattedDate, getMoney } from 'utils/transform';
import api from 'services/api';
import EnviaAcordoAprovacaoForm from 'forms/EnviaAcordoAprovacaoForm';
import { toast } from 'react-toastify';
import { ICustomizacao } from 'interfaces/Customizacao';
import { buscaCustomService } from 'services/buscaCustomizacao/buscaCustomizacao';

const useStyles = makeStyles({
  mensagemAcordo: {
    fontWeight: 'bold',
    lineHeight: '1rem',
  },
});

export default function EnviarAcordo() {
  const classes = useStyles();

  const history = useHistory();
  const [divida, setdivida] = useState<any>({ contratos: [] as any });
  const [custom, setCustom] = useState<ICustomizacao>();
  const [opcaoPagamento, setOpcaoPagamento] = useState<any>({});
  const [mensagemAcordo, setMensagemAcordo] = useState<any>(null);

  useEffect(() => {
    
    async function buscaCustomizacao() {
      const { data: newCustom } = await buscaCustomService({
        crms: divida?.crm,
        carteiraId: divida.carteiraId
      })
      setCustom(newCustom);
    }
    buscaCustomizacao();
  }, []);

  useEffect(() => {
    const localDivida = localStorage.getItem('@dividaAcordo');
    const localOpcaoPagamento = localStorage.getItem(
      '@dividaAcordoOpacaoPagamento',
    );

    if (!localDivida || !localOpcaoPagamento) {
      history.push('/');
      return;
    }
    setdivida(decryptData(localDivida));
    setOpcaoPagamento(decryptData(localOpcaoPagamento));

    // eslint-disable-next-line
  }, []);

  async function enviaAcordo(values: any) {
    const payload = {
      Crm: divida?.crm,
      Contrato: divida?.documento,
      Carteira: divida?.carteiraId,
      Valor: opcaoPagamento?.valor,
      Parcelas: opcaoPagamento?.parcelas?.length,
      DataVencimento: opcaoPagamento?.dataVencimento,
      Identificador: opcaoPagamento?.codigo,
      OpcaoDebitoConta: opcaoPagamento?.opcaoDebitoConta,
      OpcaoChequeEspecial: opcaoPagamento?.opcaoChequeEspecial,
      EnviarEmailPara: values?.email,
      EnviarSMSPara: values?.telefone?.substring(0, 15),
    };

    const { data } = await api.post('enviar-acordo-aprovacao', payload);

    if (!data.sucesso) {
      toast.error(data.msgRetorno || 'Erro no envio do boleto!');
      return;
    }

    toast.success(data.msgRetorno || 'Boleto enviado com sucesso!');

    const contato = payload.EnviarEmailPara ? 'e-mail' : 'sms';
    const mensagem = `Seu acordo foi registrado e o boleto será enviado em até 24 horas no seu ${contato}.`;
    setMensagemAcordo(mensagem);
  }

  return (
    <Box>
      <Card>
        {mensagemAcordo ? (
          <CardContent>
            <Box p={1} width="100%">
              <Typography
                variant="body2"
                component="p"
                className={classes.mensagemAcordo}
              >
                {mensagemAcordo}
              </Typography>
            </Box>
          </CardContent>
        ) : (
          <CardContent>
            <Box p={2} width="100%">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={10}>
                  <Box>
                    <Label>Opção escolhida:</Label>
                    <Value>{opcaoPagamento.texto}</Value>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Box>
                    <Label>Vencimento:</Label>
                    <Value>
                      {getFormattedDate(opcaoPagamento.dataVencimento) || '-'}
                    </Value>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {divida?.contratos?.map((contrato: any) => (
              <Box p={2} key={contrato.numero} width="100%">
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <Box>
                      <Label>Produto:</Label>
                      <Value>{contrato.produto}</Value>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Box>
                      <Label>Contrato:</Label>
                      <Value>{contrato.documento}</Value>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <Box>
                      <Label>Valor:</Label>
                      <Value>{getMoney(contrato.valor)}</Value>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} sm={3}>
                    <Box>
                      <Label>Contratação:</Label>
                      <Value>
                        {getFormattedDate(contrato.dataContratacao) || '-'}
                      </Value>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Box>
                      <Label>Atraso:</Label>
                      <Value>
                        {getFormattedDate(contrato.dataAtraso) || '-'}
                      </Value>
                    </Box>
                  </Grid>
                </Grid>
                <Divider />
              </Box>
            ))}
            <EnviaAcordoAprovacaoForm onSubmit={enviaAcordo} custom={custom} />
          </CardContent>
        )}
      </Card>
    </Box>
  );
}
