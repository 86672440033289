import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import MUIAccordionDetails from '@material-ui/core/AccordionDetails';
import { Box } from '@material-ui/core';

export const Status = styled(Typography)`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

interface LabelProps extends TypographyProps {
  textSecondary?: boolean;
  semiBold?: boolean;
  dividaConsolidada?: boolean;
}

interface AlertTitleProps {
  isMobile: boolean;
}

const LabelModel = styled(Typography)<LabelProps>``;

export const Label = styled(LabelModel).withConfig({
  shouldForwardProp: (prop: any) => !['textSecondary', 'semiBold'].includes(prop),
})`
  margin-bottom: 8px;
  font-size: 12px;

  color: ${(props: any) =>
    !!props?.dividaConsolidada ? '#818181' : 
    !!props?.textSecondary ? props.theme.palette.text.secondary : '#b2b2b2'
  };

  ${(props: any) =>
    !!props?.semiBold &&
    css`
      font-weight: 500;
    `}
`;

export const Value = styled(Typography)`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 16px;
  word-wrap: break-word;
`;

export const WhiteBold = styled.span`
  font-size: 18px;
  color: #fff;
  font-weight: 500;
`;

export const GreenBackground = styled(Box).attrs({ p: 2 })`
  background-color: ${props => props.theme.palette.darkGreen};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const AlertBannerContainer = styled(Box).attrs({ p: 2 })`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const AlertBannerContent = styled(Box)`
  border: 1px solid #6C524B;
  background: ${({ theme }) => theme.palette.chips.primary.background};
  padding: 12px;
  border-radius: 5px;
  color: #6C524B;
  p {
    font-size: 0.8rem;
  }
`;

export const AlertTitle = styled.span<AlertTitleProps>`
  font-size: ${(props: AlertTitleProps) => props.isMobile ? '0.875rem' : '1rem'} ;
  font-weight: 600;
  display: block;
  margin-bottom: 5px;
`;

export const AccordionDetails = styled(MUIAccordionDetails)`
  padding: 0;
  display: flex;
  flex-direction: column;
`;

export const OpcaoButton = styled(Button).attrs({
  variant: 'outlined',
})`
  border-color: #fff;
  color: #fff;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

export const AcordoButton = styled(Button).attrs({
  variant: 'contained',
})`
  background-color: #fff;
  color: ${({ theme }) => theme.palette.text.secondary};

  strong {
    margin-left: 4px;
  }
`;

export const HelperButton = styled.div`
    border: 1px solid #48484a;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
    margin-left: 5px;
    span {
        color: #48484a;
    }
`;

export const HelperContainer = styled.div`
   border: 1px solid #d2d3d5;
   z-index: 200;
   position: absolute;
   padding: 0px 10px;
   box-shadow: 0px 0px 8px 5px #d2d3d5;
   top: 85px;
   border-radius: 8px;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #ffffff;
`;

export const HelperText = styled.p`
   color: #48484a;
   font-size: 14px;
`;