import React from 'react';
import parse from 'html-react-parser';

import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { TextField } from 'final-form-material-ui';
import { Box } from '@material-ui/core';
import { OnChange } from 'react-final-form-listeners';

import { validate } from 'utils/validate';
import { schema } from './schema';

import { Divider, SubmitButton } from './styles';
import { BoxInformacaoAdicional2 } from 'dialogs/OpcoesParcelamentoDialog/styles';

import { format } from 'utils/form';

const focusOnErrors = createDecorator();

function EnviaAcordoAprovacaoForm({ onSubmit, custom = {} }: any) {
  const TELEFONE_MASK = format('(99) 99999-9999');
  return (
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnErrors]}
      validate={validate(schema)}
      initialValues={{}}
      render={(props: any) => (
        <form onSubmit={props.handleSubmit} noValidate>
          <Field
            name="email"
            autoFocus
            type="text"
            component={TextField}
            label="E-mail"
            margin="normal"
            fullWidth
            variant="outlined"
          />
          <OnChange name="email">
            {() => {
              if (props.values.telefone) {
                props.form.reset();
              }
            }}
          </OnChange>
          <Divider align="center">OU</Divider>

          <Field
            name="telefone"
            maxLength="14"
            type="text"
            component={TextField}
            label="Celular (SMS/Whatsapp)"
            margin="normal"
            fullWidth
            variant="outlined"
            {...TELEFONE_MASK}
          />
          <OnChange name="telefone">
            {() => {
              if (props.values.email) {
                props.form.reset();
              }
            }}
          </OnChange>

          <Box mt={1}>
            <SubmitButton
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={props.submitting}
              size="large"
            >
              Enviar acordo
            </SubmitButton>
          </Box>
          {custom?.InformacaoAdicional2 && (
            <BoxInformacaoAdicional2 mt={2}>
              {parse(custom?.InformacaoAdicional2)}
            </BoxInformacaoAdicional2>
          )}
        </form>
      )}
    />
  );
}

export default EnviaAcordoAprovacaoForm;
