import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';
import MUIButton from '@material-ui/core/Button';

export const Divider = styled(Typography)`
  text-transform: uppercase;
  font-size: 16px;
  display: flex;
  align-items: center;

  &:after,
  &:before {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid #b2b2b2;
    margin: auto;
  }

  &:before {
    margin-right: 1rem;
  }
  &:after {
    margin-left: 1rem;
  }
`;

export const SubmitButton = styled(MUIButton)`
  text-transform: none;
`;
