import * as Yup from 'yup';
import { validateBr } from 'js-brasil';

export const schema = Yup.object().shape(
  {
    email: Yup.string()
      .email()
      .when('telefone', {
        is: telefone => !telefone,
        then: Yup.string()
          .email()
          .required('Preencha pelo menos um campo'),
        otherwise: Yup.string().email(),
      }),
    telefone: Yup.string().when('email', {
      is: email => !email,
      then: Yup.string()
        .test('telefone', 'Telefone inválido', function(value) {
          if (!value) return;
          return validateBr.celular(value);
        })
        .max(14)
        .required('Preencha pelo menos um campo'),
      otherwise: Yup.string(),
    }),
  },
  [['email', 'telefone']],
);
