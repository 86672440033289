import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const viacep = axios.create({
  baseURL: 'https://viacep.com.br/ws/',
});

export const identityApi = axios.create({
  baseURL: process.env.REACT_APP_IDENTITY_URL,
});

export const bpDigitalApi = axios.create({
  baseURL: process.env.REACT_APP_BP_DIGITAL_URL,
});

export const hmlApi = axios.create({
  baseURL: process.env.REACT_APP_BP_HML_URL,
});

export default api;
