import { Box, FormHelperText } from '@material-ui/core';
import React from 'react';
import Recaptcha from 'react-recaptcha';

function RecaptchaField({ meta, input: { onChange } }) {
  let captcha;
  const showError = !!(meta.touched && meta.error);

  const setCaptchaRef = (ref) => {
    if (ref) {
      return captcha = ref;
    }
 };

 const resetCaptcha = () => {
   captcha.reset();
 }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      <Recaptcha
        ref={(r) => setCaptchaRef(r) }
        sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
        render="explicit"
        onChange={() => resetCaptcha()}
        onloadCallback={() => {}}
        verifyCallback={newValue => {
          onChange(newValue);
        }}
        expiredCallback={() => {
          onChange(null);
        }}
        hl="pt-BR"
      />
      {showError && (
        <FormHelperText style={{ alignSelf: 'flex-start' }} error={showError}>
          {meta.error}
        </FormHelperText>
      )}
    </Box>
  );
}

export default RecaptchaField;
