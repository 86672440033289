import React from 'react';
import DialogForm from 'components/DialogForm';
import { Typography, Button } from '@material-ui/core';
import { ContainerBottom } from './style';
import { ICustomizacao } from 'interfaces/Customizacao';
import parse from 'html-react-parser'

interface IInformacaoAdicionalDialogProps {
  open: boolean;
  onClose: () => void;
  custom: ICustomizacao;
}

const InformacaoAdicionalDialog = ({
  open,
  onClose,
  custom
}: IInformacaoAdicionalDialogProps) => {
  return (
    <DialogForm
      id="additonalInfo_form"
      title="Informações importantes"
      open={open}
      onClose={onClose}
      maxWidth="md"
    >
      {() => (
        <>
          <Typography style={{fontSize: 14, marginBottom: 80}}>
            {parse(custom?.InformacaoAdicionalDialog || "")}
          </Typography>
            <ContainerBottom my={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={onClose}
                size="small"
              >
                Fechar
              </Button>
            </ContainerBottom>
        </>
      )}
    </DialogForm>
  );
}

export default InformacaoAdicionalDialog;