import React from 'react';
import Component from '@material-ui/core/Drawer';
import { Divider, List, ListItem, ListItemText } from '@material-ui/core';
import { If, Then, Else } from 'react-if';

function Drawer({ onToggle, menuItems = [], ...rest }) {
  return (
    <Component {...rest} onClose={() => onToggle(false)}>
      <div
        style={{ width: 250 }}
        role="presentation"
        onClick={() => onToggle(false)}
        onKeyDown={() => onToggle(false)}
      >
        <List>
          {menuItems.map((item, index) => (
            <If key={index} condition={!!item.separator}>
              <Then>
                <Divider />
              </Then>
              <Else>
                <ListItem
                  style={{ color: '#000' }}
                  component="a"
                  href={item.href}
                  key={index}
                >
                  <ListItemText primary={item.label} />
                </ListItem>
              </Else>
            </If>
          ))}
        </List>
      </div>
    </Component>
  );
}

export default Drawer;
