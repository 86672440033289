import React from 'react';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { TextField } from 'final-form-material-ui';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { format } from 'utils/form';
import RecaptchaField from 'components/RecaptchaField';

import { validate } from 'utils/validate';
import { schema } from './schema';
import { Box, CircularProgress, Link } from '@material-ui/core';
import { Else, If, Then } from 'react-if';

import ItauParceria from 'images/itau-logo-parceria.svg';
import BradescoParceria from 'images/bradesco-logo-parceria.png';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';

const focusOnErrors = createDecorator();

function LoginForm({ onSubmit }: any) {
  const CPF_MASK = 'XXX.XXX.XXX-XX';
  const CNPJ_MASK = 'XX.XXX.XXX/XXXX-XX';
  const FINANCEIRA = process.env.REACT_APP_FINANCEIRA;

  return (
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnErrors]}
      subscription={{ submitting: true, values: true }}
      validate={validate(schema)}
      render={({ handleSubmit, submitting, values }: any) => {
        const isCnpj = values?.documento?.toString().length > 11;
        const documentoMask = format(isCnpj ? CNPJ_MASK : CPF_MASK);

        return (
          <form onSubmit={handleSubmit} noValidate>
            <Field
              name="documento"
              id="documento-input"
              component={TextField}
              {...documentoMask}
              InputLabelProps={{
                shrink: true,
              }}
              label="Digite seu documento (CPF/CNPJ)"
              margin="normal"
              fullWidth
              variant="outlined"
            />
            {(FINANCEIRA === 'bradesco') && (
              <Field
                name="nome"
                id="nome-input"
                component={TextField}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Digite seu nome completo"
                margin="normal"
                fullWidth
                variant="outlined"
              />
            )}

            {process.env.REACT_APP_PASS == "" && (
              <Field
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'stretch',
                }}
                name="recaptcha"
                component={RecaptchaField}
              />
            )}
            <Box mt={1}>
              {FINANCEIRA === 'bradesco' ? (
                <Button
                  disabled={submitting}
                  fullWidth
                  size="large"
                  style={{ textTransform: 'none', height: 56, backgroundColor: '#E1173F', color: '#fff', borderRadius: 100 }}
                  variant="contained"
                  type="submit"
                >
                  <If condition={submitting}>
                    <Then>
                      <CircularProgress style={{ color: '#fff' }} size={23} />
                    </Then>
                    <Else>Consultar</Else>
                  </If>
                </Button>
              ) : (
                <Button
                  disabled={submitting}
                  fullWidth
                  size="large"
                  style={{ textTransform: 'none', height: 56}}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  <If condition={submitting}>
                    <Then>
                      <CircularProgress size={23} />
                    </Then>
                    <Else>Consultar CPF</Else>
                  </If>
                </Button>
              )}
            </Box>

            <Box
              mt={FINANCEIRA === 'itau' || FINANCEIRA === 'bradesco' ? 3 : 1}
              display="flex"
              flexDirection="column"
            >
              <Box
                mb={2}
                display="flex"
                justifyContent="center"
                alignItems="flex-end"
              >
                {FINANCEIRA === 'itau' && (
                  <img
                    style={{ height: 50, width: 280, objectFit: 'cover'}}
                    src={ItauParceria}
                    alt="Parceria Itaú"
                  />
                )}
              </Box>
              {FINANCEIRA === 'itau' && (
                <>
                  <Typography
                    variant="caption"
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    Consulte a nossa parceria com o banco através do link:
                  </Typography>
                  <Link
                    variant="caption"
                    href="https://www.itau.com.br/renegociacao"
                    target="_blank"
                    style={{
                      textAlign: 'center',
                      marginTop: 5,
                      color: blue[600],
                      textDecoration: 'underline',
                    }}
                  >
                    https://www.itau.com.br/renegociacao
                  </Link>
                </>
              )}
            </Box>
          </form>
        );
      }}
    />
  );
}

export default LoginForm;
