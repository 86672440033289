import React, { useEffect } from 'react';

import BackButton from 'components/BackButton';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Hidden,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import DocumentIcon from 'images/document-gray.svg';
import { Status, Label, Value, LabelLimit } from 'components/DividaAcordoItem/styles';
import { When } from 'react-if';
import { getFormattedDate, getMoney } from 'utils/transform';
import { FirmarAcordoButton } from './styles';
import { IDivida } from 'interfaces/Divida';
import { IOpcaoPagamento } from 'interfaces/BuscaOpcaoPagamento';
import { IResumoBoleto } from 'interfaces/ResumoBoleto';
import { ICustomizacao } from 'interfaces/Customizacao';
import LimitesAjustados from 'components/LimitesAjustados/LimitesAjustados';
import ResumoBoletoDetalhesUsingAPI from 'components/Bradesco/ResumoBoletoDetalhes/ResumoBoletoDetalhesUsingAPI';
import ResumoBoletoDetalhes from 'components/Bradesco/ResumoBoletoDetalhes/ResumoBoletoDetalhes';

interface IBradescoResumoBoleto2 {
  history: any;
  custom:ICustomizacao;
  isDesktop: boolean;
  resumoBoleto: IResumoBoleto;
  divida: IDivida;
  parcelamento: IOpcaoPagamento;
  bank?: any;
  onEmitirBoleto: any;
  loadingEmitirBoleto: boolean;
  isMobile: boolean;
  usaResumoApi: boolean;
}

const BradescoResumoBoleto2 = ({
  history,
  custom,
  isDesktop,
  resumoBoleto,
  divida,
  parcelamento,
  bank,
  onEmitirBoleto,
  loadingEmitirBoleto,
  isMobile,
  usaResumoApi
}: IBradescoResumoBoleto2) => {

  return (
    <div>
      <BackButton
        onClick={() => {
          history.goBack();
        }}
      >
        Voltar para a home
      </BackButton>
      <Box mt={2} mb={2}>
        <Typography
          variant="h5"
          style={{
            fontWeight: 500,
            color: "#000000DE"
          }}
        >
          Revise as informações abaixo e confirme o acordo com a melhor
          negociação
        </Typography>
      </Box>

      {!!parcelamento?.texto && (
        <Box mt={2} mb={2}>
          <Paper style={{}}>
            <Box p={3}>
              <Grid
                container
                direction="row"
                justifyContent={isMobile ? 'center' : 'space-between'}
                alignItems="center"
                spacing={2}
              >
                <Grid item container xs={12} md={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="caption"
                      align={isMobile ? 'center' : 'left'}
                      color="secondary"
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                      }}
                    >
                      {parcelamento.texto}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      )}

      <Box>
        <Paper>
          <Box width="100%" px={4} py={2}>
            <Box display="flex" alignItems="center">
              <Box mr={2}>
                <Status color="primary">
                  <strong>Dívida ativa</strong>
                </Status>
              </Box>
              <Box mr={1}>
                <img
                  style={{ marginBottom: 2 }}
                  src={DocumentIcon}
                  alt="Documento"
                />
              </Box>
              <Status>
                <span>
                  {Number(resumoBoleto?.contratos?.length) || Number(divida?.contratos?.length)} Contrato
                  {(Number(resumoBoleto?.contratos?.length) > 1 || Number(divida?.contratos?.length) > 1) && 's'}
                </span>
              </Status>
            </Box>
            <Box>
              <Typography style={{ fontSize: 28 }} variant="h6">
                <strong>{bank.financeira}</strong>
              </Typography>
            </Box>
            
            {!!usaResumoApi ? (
              <ResumoBoletoDetalhesUsingAPI 
                resumoBoleto={resumoBoleto} 
                isDesktop={isDesktop} 
                parcelamento={parcelamento} 
              />
            ) : (
              <ResumoBoletoDetalhes 
                divida={divida}
                parcelamento={parcelamento}
                isDesktop={isDesktop}
                custom={custom}
              />
            )} 

            <Box mt={3}>
              <FirmarAcordoButton
                onClick={() => onEmitirBoleto()}
                color="primary"
                variant="contained"
              >
                {!loadingEmitirBoleto ? (
                    resumoBoleto?.indicadorFormalizacao 
                    ? 'Confirmar Formalização'
                    : 'Confirmar Acordo'
                ) : (
                  <CircularProgress size={20} style={{ color: 'white' }} />
                )}
              </FirmarAcordoButton>
            </Box>

            <When condition={Number(parcelamento?.parcelas?.length) > 1 && !usaResumoApi}>
              <Box mt={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={11}>
                    <Label>
                      <strong>IOF:</strong> Imposto Operacional Financeiro
                    </Label>
                  </Grid>
                  <Grid item xs={12} sm={11}>
                    <Label>
                      <strong>CET:</strong> Custo Efetivo Total
                    </Label>
                  </Grid>
                </Grid>
              </Box>
            </When>
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default BradescoResumoBoleto2;
