import React from 'react';
import { TextField, Box, Typography } from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TelegramIcon from '@material-ui/icons/Telegram';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import { format } from 'utils/form';
import { LinkButton, SubmitButton } from './styles';

const WHATSAPP = 1;
const PHONE = 3;
const CALLBACK = 4;

const FALE_CONOSCO = 5;
const RENEGOCIE = 6;
const ENVIE_EMAIL = 7;
const VERIFIQUE_BRADESCO = 10;
const TELEGRAM = 8;

export const tiposContato: any = {
  [WHATSAPP]: {
    render: ({ contato, insereContatoSelecionado }: any) => (
      <Box key={contato.tipoContatoId} mb={1}>
        <LinkButton
          color="secondary"
          fullWidth
          size="large"
          variant="contained"
          onClick={() => {
            insereContatoSelecionado({ tipoContatoId: contato.tipoContatoId });
            window.open(contato.url);
          }}
          startIcon={<WhatsAppIcon />}
        >
          Whatsapp
        </LinkButton>
      </Box>
    ),
  },
  [PHONE]: {
    render: ({ contato, insereContatoSelecionado }: any) => (
      <Box key={contato.tipoContatoId} mb={1}>
        <LinkButton
          fullWidth
          color="primary"
          variant="contained"
          onClick={() => {
            insereContatoSelecionado({ tipoContatoId: contato.tipoContatoId });
            window.open(contato.url);
          }}
          size="large"
          startIcon={<PhoneForwardedIcon />}
        >
          <Typography>{contato?.descricao || "Ligue para nosso atendimento"}</Typography>
        </LinkButton>
      </Box>
    ),
  },
  [CALLBACK]: {
    render: ({ setTelefone, telefone, sendPhone, contato, error = {} }: any) => (
      <Box key={contato.tipoContatoId}>
        <Box mt={1}>ou deixe seu número e ligamos para você</Box>
        <TextField
          name="phone"
          type="phome"
          label="Telefone"
          margin="normal"
          fullWidth
          // maxLength="14"
          value={format('(XX) XXXXX-XXXX').format(telefone)}
          onChange={event => setTelefone(event.target.value)}
          variant="outlined"
          helperText={error.telefone?.message}
          error={error.telefone?.error}
          InputProps={{
            endAdornment: (
              <SubmitButton
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => sendPhone(contato)}
              >
                enviar
              </SubmitButton>
            ),
          }}
        />
      </Box>
    ),
  },
  [FALE_CONOSCO]: {
    render: ({ contato }: any) => (
      <span key={contato.tipoContatoId}> FALE_CONOSCO </span>
    ),
  },
  [RENEGOCIE]: {
    render: ({ contato }: any) => (
      <span key={contato.tipoContatoId}> RENEGOCIE </span>
    ),
  },
  [ENVIE_EMAIL]: {
    render: ({ contato }: any) => <span key={contato.tipoContatoId}> </span>,
  },
  [VERIFIQUE_BRADESCO]: {
    render: ({ contato }: any) => <span key={contato.tipoContatoId}> </span>,
  },
  [TELEGRAM]: {
    render: ({ contato, insereContatoSelecionado }: any) => (
      <Box key={contato.tipoContatoId} mb={1}>
        <LinkButton
          color="secondary"
          fullWidth
          size="large"
          variant="contained"
          onClick={() => {
            insereContatoSelecionado({ tipoContatoId: contato.tipoContatoId });
            window.open(contato.url);
          }}
          startIcon={<TelegramIcon />}
        >
          Telegram
        </LinkButton>
    </Box>
    ),
  },
};
