import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Logout from 'images/logout.svg';
import WarningPrimary from 'images/warning-primary.svg';
import CheckSecondary from 'images/check-secondary.svg';
import Chip from 'components/Chip';
import {
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  Typography,
} from '@material-ui/core';
import { useAuth } from 'contexts/auth';
import { When } from 'react-if';
// import api from 'services/api';
// import { useEffect } from 'react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${props => props.theme.breakpoints.up('sm')} {
    height: calc(100vh - 120px);
  }
`;

const LogoutButton = styled(Button).attrs({
  startIcon: <img src={Logout} alt="Logout Icon" />,
})`
  color: #252525;
  text-transform: none;
  margin-top: auto;
  position: fixed;
  bottom: 10px;
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

function AuthLayout({ children, logged = false }) {
  const { signOut, user, dividasAuthContext, acordosAuthContext } = useAuth();
  const [dividas, setDividas] = useState([]);
  const [acordos, setAcordos] = useState([]);

  useEffect(() => {
    setDividas(dividasAuthContext);
    setAcordos(acordosAuthContext);
  }, [dividasAuthContext, acordosAuthContext]);

  if (logged) {
    const cpfMask = `${user?.cnpJ_CPF?.slice(0, 3)}.${user?.cnpJ_CPF?.slice(
      3,
      6,
    )}.***`;

    return (
      <Wrapper>
        <Header logged={logged} />
        <Container style={{ paddingTop: 20, paddingBottom: 20 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} style={{ position: 'relative' }}>
              <LeftContent>
                <Typography variant="h5">
                  <strong>{user?.nome}</strong>
                </Typography>
                <Box mt={1}>
                  <Typography variant="body2">
                    <strong>CPF: </strong>
                    <span>{cpfMask}</span>
                  </Typography>
                </Box>
                <When condition={!!dividas?.length}>
                  <Box mt={2}>
                    <Chip
                      label="Débitos afetando seu CPF"
                      color="primary"
                      icon={<img src={WarningPrimary} alt="Warning" />}
                    />
                  </Box>
                </When>
                <When condition={!!acordos?.length}>
                  <Box mt={2}>
                    <Chip
                      label={`${acordos.length} Acordos em andamento`}
                      color="secondary"
                      icon={<img src={CheckSecondary} alt="Check" />}
                    />
                  </Box>
                </When>
                <Hidden xsDown implementation="css">
                  <LogoutButton onClick={signOut}>Sair</LogoutButton>
                </Hidden>
              </LeftContent>
            </Grid>
            <Grid item xs={12} sm={8}>
              {children}
            </Grid>
          </Grid>
        </Container>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Header logged={logged} />
      <Main>{children}</Main>
      <Footer />
    </Wrapper>
  );
}

export default AuthLayout;
