import { Box, Card } from "@material-ui/core";
import styled from "styled-components";

export const UpperSectionContainer = styled.div`
  background: ${props => props.theme.palette.bradescoGradiente.main};
  width: 100vw;
  padding-top: 160px;
  padding-bottom: 60px;
  font-family: "Bradesco-Sans-Regular";
`;

export const UpperSectionContent = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 1000px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
`;

export const LoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FormContent = styled.div`
  max-width: 520px;
  h3, h1 {
    color: white;
    font-weight: 400;
    margin: 0;
  }

  h3 {
    letter-spacing: 2.6px;
    text-transform: uppercase;
    font-size: 13px;
    margin-bottom: 10px;
  }

  h1 {
    margin-bottom: 30px;
    font-size: 40px;
  }

  @media (max-width: 1280px) {
    margin: 0 20px;
  }
`;

export const Form = styled(Card)
  .attrs({
    elevation: 3,
    maxWidth: 360,
  })
  .withConfig({
    shouldForwardProp: prop => !['maxWidth'].includes(prop),
  })`
  max-width: ${props => props.maxWidth}px;
  width: 100%;
  border-radius: 15px;

  @media (max-width: 1000px) {
    margin: 0 auto;
  }

  ${props => props.theme.breakpoints.down('xs')} {
    max-width: none;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 675;
  padding: '40px 0 20px 0';
  height: '100%';
  img {
    width: 100%;
  }

  div {
    width: 100%;
    max-width: 85%;
    margin: 0 auto;
  }

  a {
    color: white;
    text-decoration: underline;
  }

  @media (max-width: 1000px) {
    margin-top: 20px;

    img {
      max-width: 550px;
    }
  }
`;

export const SegurancaContainer = styled.div`
  background-color: #fff;
  width: 100vw;
  padding: 80px 0;
  font-family: "Bradesco-Sans-Regular";
`;

export const SegurancaContent = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1280px) {
    padding: 0 20px;
  }
`;

export const SegurancaCardContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  width: 100%;
  max-width: 1280px;

  div:nth-of-type(2) {
    margin: 0 80px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;

    div:nth-of-type(2) {
      margin: 20px 0;
    }
  }
`;

export const SegurancaCard = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  max-width: 289px;
  padding: 0;

  div {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    img {
      max-height: 70px;
    }
  }

  a {
    color: ${props => props.theme.palette.bradesco.main};
    text-decoration: underline;
    font-family: "Bradesco-Sans-Bold";
  }
`;

export const VantagensContainer = styled.div`
  width: 100vw;
  font-family: "Bradesco-Sans-Regular";
  background-color: #F4F4F6;
`;

export const VantagensContent = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 80px 0;

  @media (max-width: 1280px) {
    flex-direction: column;
    margin: 0 20px;
  }
`;

export const VantagensSectionTitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 1278px) {
    margin-bottom: 15px;
  }

  @media (max-width: 800px) {
    align-items: center;
  }
`;

export const VantagensCardsContainer = styled(Box)`
  display: flex;
  flex-direction: row;

  div:nth-of-type(2) {
    margin: 0 20px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;

    div:nth-of-type(2) {
      margin: 20px 0;
    }
  }
`;

export const VantagensCard = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 240px;
  padding: 25px;
  border: 1px solid #C6C6C6;
  border-radius: 15px;
`;

export const QuemSomosContainer = styled.div`
  width: 100vw;
  font-family: "Bradesco-Sans-Regular";
  background-color: #fff;
`;

export const QuemSomosContent = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 80px 0;

  @media (max-width: 1280px) {
    padding: 80px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const QuemSomosTitleContainer = styled(Box)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const QuemSomosImgContainer = styled(Box)`
  width: 100%;
  max-width: 530px;

  img {
    width: 100%;
  }
`;

export const LocalizacaoContainer = styled.div`
  width: 100vw;
  font-family: "Bradesco-Sans-Regular";
  background-color: #F4F4F6;
`;

export const LocalizacaoContent = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;

  @media (max-width: 1280px) {
    padding: 40px 20px;
  }

  @media (max-width: 850px) {
    flex-direction: column;
    align-items: center;

    div {
      margin-top: 20px;
    }

    div:nth-of-type(1) {
      margin-top: 0;
    }
  }
`;

export const MidiaContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  span {
    font-family: Bradesco-Sans-Bold;
  }
`;


export const LinkButton = styled.a`
  width: 30px;
  height: 30px;
  background-color: ${({ theme }) => theme.palette.bradesco.main};
  border-radius:50px;
  text-align:center;
  font-size:30px;
  transition: opacity 0.7s ease-out;
  margin-right: 10px;

  img {
    margin-bottom: 20px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const PassosSection = styled.div`
  background: ${props => props.theme.palette.bradescoGradiente.main};
  width: 100vw;
  padding: 80px 0;
  font-family: "Bradesco-Sans-Regular";
`;

export const PassosContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 560px) {
    padding: 0 20px;
  }

`;

export const PassosCard = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #fff;
  padding-right: 20px;

  p {
    color: #fff;
    font-size: 16px;
    font-family: Bradesco-Sans-Regular;
    text-align: left;
  }

  div {
    margin: 0 20px;
    border-radius: 100px;
    background-color: #fff;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-family: Bradesco-Sans-Bold;
      font-size: 16px;
      color: ${({ theme }) => theme.palette.bradesco.main};
    }
  }

  @media (max-width: 560px) {
    padding-right: 0;

    div {
      margin: 0 10px;
    }
  }
`;

export const DuvidasFrequentesContainer = styled.div`
  width: 100vw;
  font-family: "Bradesco-Sans-Regular";
  background-color: #fff;
`;

export const DuvidasFrequentesContent = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0;

  @media (max-width: 1280px) {
    padding: 80px 20px;
  }

  .accordeon {
    padding: 0 14px;
    border: 1px solid #C6C6C6;
    margin-bottom: 8px;
    h4 {
      font-size: 16px;
      font-family: Bradesco-Sans-Bold;
      color: #4D4E53;
      line-height: 1;
    }
    p {
      color: #4D4E53;
      font-size: 14px;
    }
    span {
      font-family: Bradesco-Sans-Bold;
    }
  }
`;

export const ContatoContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #EBEBEB;
  padding: 30px;
  border-radius: 5px;

  p {
    text-align: left;
  }

  @media (max-width: 610px) {
    flex-direction: column;

    p {
      text-align: center;
    }

    p:nth-of-type(2) {
      margin-bottom: 10px;
    }
  }
`;