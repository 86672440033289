import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

export const Title = styled(Typography)`
  color: #182825;
  font-weight: 600;
`;

export const Wrapper = styled.div`
  background-color: ${props => props.theme.palette.darkGreen};
  padding: 20px 10px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 20px;
  }

  h6 {
    line-height: 1.2;
  }
`;

export const BackButton = styled(Button)`
  text-transform: none;
  color: #b2b2b2;
`;
