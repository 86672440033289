import React from 'react';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const BackButton = styled(Button).attrs({
  startIcon: <ArrowBackIcon color="primary" />,
})`
  text-transform: none;
  color: #b2b2b2;
`;

export default BackButton;
