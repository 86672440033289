import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, IconButton, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import { Else, If, Then } from 'react-if';

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0px;
`;

const Title = styled(DialogTitle)`
  font-weight: bold;
  margin-top: ${props => props.theme.spacing(2)}px;
`;

const DialogForm = ({ children, onClose = () => { }, title = '' || {}, id, ...rest }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Dialog fullWidth onClose={onClose} {...rest} fullScreen={isMobile && !rest.windowed}
      PaperProps={{ style: { borderRadius: rest.financeira === "bradesco" ? 15 : 4 } }}>
      <If condition={React.isValidElement(title)}>
        <Then>
          <Box mr={4}>{title}</Box>
        </Then>
        <Else>
          <Title>{title}</Title>
        </Else>
      </If>

      <CloseButton onClick={onClose}>
        <CloseIcon color="primary" />
      </CloseButton>
      <DialogContent id={id}>{children({ onClose, ...rest })}</DialogContent>
    </Dialog>
  );
}

export default DialogForm;
