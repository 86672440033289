import React, { useState } from 'react';

import LoginForm from 'forms/LoginForm';
import { FormContainer } from 'styles/global';
import { useAuth } from 'contexts/auth';
import { useHistory } from 'react-router-dom';
import { Button, Box, Grid, Typography } from '@material-ui/core';
import { Else, If, Then } from 'react-if';
import EscolherBanco from 'components/EscolherBanco';
import bannerLogin from 'images/banner-login.png';
import bannerMobile from 'images/banner-login-mobile.png';
import seloBradesco from 'images/selo-bradesco-horizontal.png';
import { Check } from '@material-ui/icons';
import Touch from 'images/touch.svg';
import Discount from 'images/discount.svg';
import Headphones from 'images/headphones.svg';
import styled from 'styled-components';
import { encryptData } from 'utils/crypto';

import { ILoginPayload } from 'interfaces/GeneralInterfaces';
import { IUser } from 'interfaces/User';
import ValidateNameDialog from 'dialogs/ValidateNameDialog/ValidateNameDialog';
import CredorNotFoundDialog from 'dialogs/CredorNotFoundDialog/CredorNotFoundDialog';
import { MENSAGEM_DADOS_INVALIDOS } from 'utils/constants';
import LoginBradesco from './LoginBradesco';

const UpperSectionGrid = styled(Grid)`
  max-height: 708px;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: flex-end;
  background-image: url(${bannerLogin});
  background-size: cover;
  background-position-x: center;
  padding-right: 25%;

  ${props => props.theme.breakpoints.down('sm')} {
    justify-content: center;
    padding-right: 0;
    background-image: url(${bannerMobile});
    background-size: cover;
    background-position-x: center;
    background-position-y: top;
  }
`;

const MiddleSection = styled.section`
  display: flex;
  padding: 10px;

  ${props => props.theme.breakpoints.up('sm')} {
    padding: 150px 10px;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
  }
`;

function Login() {
  const history = useHistory();
  const { signIn, setBank } = useAuth();
  const [preventLogin, setPreventLogin] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorLoginMessage, setErrorLoginMessage] = useState('');
  const [openValidateName, setOpenValidateName] = useState(false);
  const [openCredorNotFound, setOpenCredorNotFound] = useState(false);
  const FINANCEIRA = process.env.REACT_APP_FINANCEIRA || '';

  const onSubmit = async (values: ILoginPayload) => {
    setErrorLoginMessage('');
    const user: IUser | undefined = await signIn(values);

    if (user?.mensagemRetorno?.sucesso && !user?.credores?.length) {
      history.push('/sucesso');
      return;
    }

    if(!user?.mensagemRetorno?.sucesso){
      if(user?.mensagemRetorno?.msgRetorno === MENSAGEM_DADOS_INVALIDOS && user.nome == undefined){
        setErrorLoginMessage(MENSAGEM_DADOS_INVALIDOS);
      }else{
        setOpenCredorNotFound(true);
      }
    }

    if (user?.mensagemRetorno?.sucesso && Number(user?.credores?.length) > 1) {
      setPreventLogin(true);
      return;
    }

    if (!!user?.credores?.length) {
      const [bank] = user.credores;

      if (bank?.financeira === 'Bradesco') {
        if (FINANCEIRA === "bradesco" && !user.nomeMae) {
          setBank(bank);
          localStorage.setItem('@bank', encryptData(bank));
          history.push('/');
        };

        setBank(bank);
        setOpenValidateName(true);
      }
      else {
        setBank(bank);
        localStorage.setItem('@bank', encryptData(bank));
        history.push('/');
      }
    }
  };

  const handleCloseValidateNameDialog = () => {
    setOpenValidateName(false);
    setErrorMessage('');
  }

  const onChooseBanco: any = (value: any) => {
    if (value.financeira === 'Bradesco') {
      setBank(value);

      if (FINANCEIRA === "bradesco") setOpenValidateName(true);
    }
    else {
      setBank(value);
      localStorage.setItem('@bank', encryptData(value));
      history.push('/');
    }
  };

  const onFocusDocumentoInput = () => {
    const input = document.getElementById('documento-input');

    window.scrollTo(0, 0);
    input?.focus();
  };

  return (
    <>
      {FINANCEIRA === 'bradesco' ? (
        <LoginBradesco
          preventLogin={preventLogin}
          setPreventLogin={setPreventLogin}
          onSubmit={onSubmit}
          msgLogin={errorLoginMessage}
          onChooseBanco={onChooseBanco}
        />
      ) : (
        <Grid container justifyContent="center" >
          <UpperSectionGrid item container xs={12}>
            <Box maxWidth={400} py={6} px="auto">
              <Typography
                className="custom-typography"
                variant="h3"
                style={{
                  color: 'white',
                  fontSize: '2.5rem',
                  fontFamily: 'Baloo-Tamma-2',
                  marginBottom: 40,
                  textShadow: '0.03em 0.03em 0.01em #333',
                  textAlign: "center",
                  lineHeight: '48px'
                }}
              >
                Negocie suas dívidas e mude o rumo da sua vida agora mesmo!
              </Typography>

              <FormContainer maxWidth={354}>
                <If condition={preventLogin}>
                  <Then>
                    <EscolherBanco
                      onReset={() => setPreventLogin(false)}
                      onChooseBanco={onChooseBanco}
                    />
                  </Then>
                  <Else>
                    <Typography
                      style={{
                        fontFamily: 'Raleway',
                        fontWeight: '700px',
                        lineHeight: '17px',
                        textAlign: 'justify',
                        fontSize: 17,
                        marginBottom: '0.897500rem'
                      }}
                      variant="h6"
                    >
                      Consulte na Bellinati se existem dívidas no seu CPF ou
                      acompanhe seus acordos
                    </Typography>
                    {FINANCEIRA === 'bradesco' && (
                      <Box
                        alignItems="center"
                        alignContent="center"
                        alignSelf="center"
                        py="auto"
                        px="auto"
                      >
                        <a href="https://banco.bradesco/assets/common/pdf/Empresas-Credenciadas-de-Cobranca-Amigavel.pdf#page=3">
                          <img
                            style={{ maxWidth: '92%', paddingBottom: 25 }}
                            src={seloBradesco}
                            alt="Selo Bradesco"
                          />
                        </a>
                      </Box>
                    )}
                    {(FINANCEIRA === 'bradesco') && (
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          lineHeight: 1.0,
                          marginBottom: '20px',
                          textAlign: 'justify',
                          fontSize: 17,
                          color: 'red',
                        }}
                        variant="h6"
                      >
                        {errorLoginMessage}
                      </Typography>
                    )}
                    <LoginForm onSubmit={onSubmit} />
                  </Else>
                </If>
              </FormContainer>
            </Box>
          </UpperSectionGrid>
          <Grid item container xs={12} sm={9} justifyContent="space-evenly">
            <Grid item xs={12} sm={5} >
              <MiddleSection>
                <Typography variant="h4" style={{ fontWeight: 600, fontSize: 32 }}>
                  Consulte seu CPF, e assuma o controle do seu financeiro
                </Typography>
              </MiddleSection>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MiddleSection>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 'normal', marginBottom: 20 }}
                >
                  <Check style={{ color: '#5AD87C', marginRight: 10 }} />
                  Negociações facilitadas
                </Typography>
                <Typography variant="h6" style={{ fontWeight: 'normal' }}>
                  <Check style={{ color: '#5AD87C', marginRight: 10 }} />
                  Parcelamento e desconto
                </Typography>
              </MiddleSection>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            style={{ backgroundColor: '#EAEAEA' }}
          >
            <Grid item>
              <Box mt={10} mb={10}>
                <Typography
                  variant="h3"
                  style={{ fontFamily: "Baloo-Tamma-2", lineHeight:'56.8px', fontWeight: 600, textAlign: 'center', fontSize:'32px'}}
                >
                  Limpe seu nome agora mesmo!
                </Typography>
              </Box>
            </Grid>
            <Grid item container xs={12} justifyContent="space-evenly" alignItems="center">
              <Grid item xs={12} sm={4} lg={2}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={Touch} alt="touch" />
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600, textAlign: 'center' }}
                  >
                    Negocie automaticamente
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: 'normal',
                      fontSize: 16,
                      textAlign: 'center',
                      color: '#707070',
                    }}
                  >
                    Um portal de negociação totalmente automático.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={Discount} alt="selo de desconto" />
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600, textAlign: 'center' }}
                  >
                    Descontos e facilitação dos pagamentos
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: 'normal',
                      fontSize: 16,
                      textAlign: 'center',
                      color: '#707070',
                    }}
                  >
                    Tenha acesso a descontos e parcelamento das dívidas.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={Headphones} alt="suporte" />
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600, textAlign: 'center' }}
                  >
                    Suporte rápido e personalizado
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: 'normal',
                      fontSize: 16,
                      textAlign: 'center',
                      color: '#707070',
                    }}
                  >
                    Se tiver dúvidas, nossa equipe entra em contato para ajudar!
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mt={10}
                  mb={5}
                >
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    style={{
                      backgroundColor: '#A82047',
                      paddingRight: 40,
                      paddingLeft: 40,
                      marginBottom: '14%',
                    }}
                    onClick={onFocusDocumentoInput}
                  >
                    Consultar CPF
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <ValidateNameDialog
        financeira={FINANCEIRA}
        open={openValidateName}
        onClose={handleCloseValidateNameDialog}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      <CredorNotFoundDialog
        open={openCredorNotFound}
        onClose={() => setOpenCredorNotFound(false)}
        financeira={FINANCEIRA}
      />
    </>
  );
}

export default Login;
