import { createTheme } from '@material-ui/core/styles';

const FINANCEIRA = process.env.REACT_APP_FINANCEIRA;

const theme = createTheme({
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  typography: {
    fontFamily: ['Poppins', 'Bradesco-Sans-Regular', 'Bradesco-Sans-Bold','Baloo-Tamma-2','Raleway'].join(','),
  },
  overrides: {
    MUIDataTableSearch: {
      searchText: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    MUIDataTableHeadCell: {
      sortAction: {
        alignItems: 'center',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      label: {
        textTransform: 'none',
      },
    },
  },
  palette: {
    bradesco: {
      main: '#CC092F',
    },
    bradescoGradiente: {
      main: 'linear-gradient(90deg, #CC092F 64.4%, #B81570 100%)',
    },
    darkOrange: '#252A34',
    darkGreen: '#5AD87C',
    primary: {
      separator: FINANCEIRA ==='bradesco' ? '#CC092F' : '#EAEAEA',
      main: FINANCEIRA === 'bradesco' ? '#CC092F' : '#A82047',
      dark: FINANCEIRA === 'bradesco' ? '#c92c4b' : '#FF2E63', 
      contrastText: '#fff',
    },
    secondary: {
      main: '#4DCC70',
      dark: '#43B763',
      contrastText: '#182825',
    },
    typography: {
      darkGreen: '#115222',
    },
    text: {
      secondary: '#115222',
    },
    chips: {
      primary: {
        background: '#F4DDD9',
        color: '#7D1416',
      },
      secondary: {
        background: '#DAEEDF',
        color: '#115222',
      },
    },
  },
});

export default theme;
