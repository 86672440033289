import { setupYup } from 'config/yup';
import { validateBr } from 'js-brasil';

const Yup = setupYup();
const FINANCEIRA = process.env.REACT_APP_FINANCEIRA;

export const schema = Yup.object().shape({
  documento: Yup.string()
    .required()
    .test('cpf-cnpj', 'Documento Inválido', function(value) {
      if (!value) return;
      if (value.length > 11) {
        return validateBr.cnpj(value);
      }
      return validateBr.cpf(value);
    }),
  nome: FINANCEIRA === 'bradesco' ? Yup.string().required() : Yup.string(),
  recaptcha: Yup.string(),
});
