import { Box } from "@material-ui/core";
import styled from 'styled-components';

export const ContainerBottom = styled(Box)`
  width: 100%;
  background: #fff;
  padding: 10px;
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  ::before {
    position: absolute;
    content: '';
    width: 100%;
    top: -32px;
    left: 0;
    height: 32px;
    background-color: #fff;
    -webkit-mask-image: -webkit-gradient(linear,left bottom,left top,from(#000),to(transparent));
    -webkit-mask-image: linear-gradient(356deg,#000,transparent);
    mask-image: -webkit-gradient(linear,left bottom,left top,from(#000),to(transparent));
    mask-image: linear-gradient(0deg,#000,transparent);
  }
`;