import React from 'react';
import MUIButton from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styled from 'styled-components';
import { Title } from 'components/typography';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { useAuth } from 'contexts/auth';
import PendenciasImage from 'images/pendencias.svg';

const Button = styled(MUIButton)`
  color: #b2b2b2;
  text-transform: none;
`;

const IllustrationContainer = styled(Paper).attrs({
  elevation: 0,
})`
  background-color: #efecca;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;

  img {
    margin-right: 20px;
    max-width: 100px;
  }
`;

const OptionButton = styled(MUIButton).attrs({
  color: 'primary',
  fullWidth: true,
  variant: 'contained',
  disableElevation: true,
})`
  height: 50px;
  text-transform: none;
  font-weight: 500;
`;

const VerDivida = styled.span`
  color: ${({ theme }) => theme.palette.darkOrange};
`;

function EscolherBanco({ onReset = () => {}, onChooseBanco = () => {} }) {
  const { user } = useAuth();

  return (
    <div>
      <Button onClick={onReset} startIcon={<ArrowBackIcon color="primary" />}>
        Alterar CPF
      </Button>
      <Box my={3}>
        <Title>{user?.name}</Title>
      </Box>
      <IllustrationContainer>
        <img src={PendenciasImage} alt="Pendências do seu CPF" />
        <Typography
          style={{ fontWeight: 500, fontSize: 20, lineHeight: 1.2 }}
          variant="h6"
        >
          <strong>Você tem {user.credores.length} pendências em seu CPF</strong>
        </Typography>
      </IllustrationContainer>
      <Box mb={1}>
        <Typography>O que encontramos</Typography>
      </Box>
      <Grid container spacing={1}>
        {user.credores.map(item => (
          <Grid key={item.financeira} item xs={12}>
            <OptionButton onClick={() => onChooseBanco(item)}>
              <Box width="100%" display="flex" justifyContent="space-between">
                <span>{item.financeira}</span>
                <VerDivida>Ver dívida</VerDivida>
              </Box>
            </OptionButton>
          </Grid>
        ))}
      </Grid>
      <Box mt={3}>
        <Typography align="center" style={{ fontSize: 12 }}>
          Conheça suas opções e limpe seu nome!
        </Typography>
      </Box>
    </div>
  );
}

export default EscolherBanco;
